import { createContext, FC, ReactNode, useContext, useState } from "react";

interface RootLayoutProviderProps {
  children: ReactNode;
}

export type RootContextProps = {
  isMobile: boolean;
  setIsMobile?: (e: boolean) => void;
  isMobileNavShowing: boolean;
  setIsMobileNavShowing?: (e: boolean) => void;
};

const defaultState = {
  isMobile: false,
  isMobileNavShowing: false,
};

const RootContext = createContext<RootContextProps>(defaultState);

export const RootLayoutProvider: FC<RootLayoutProviderProps> = ({
  children,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileNavShowing, setIsMobileNavShowing] = useState(false);

  const value = {
    isMobile,
    setIsMobile,
    isMobileNavShowing,
    setIsMobileNavShowing,
  };

  return <RootContext.Provider value={value}>{children}</RootContext.Provider>;
};

export const useLayout = () => {
  const context = useContext(RootContext);
  return context;
};

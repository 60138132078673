import React, { FC } from "react";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

const LoginSignUpButtons: FC = () => {
  return (
    <div className="space-x-1">
      <ButtonSecondary
        fontSize="text-sm xl:text-base font-medium"
        focusClass={false}
        href="/login"
      >
        Login
      </ButtonSecondary>
      <ButtonPrimary fontSize="text-sm xl:text-base font-medium" href="/signup">
        Sign up
      </ButtonPrimary>
    </div>
  );
};

export default LoginSignUpButtons;

import NcInputSelect from "components/NcInputSelect/NcInputSelect";
import { isObject } from "lodash";
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { LocationCreateProps } from ".";
import LocationCreateLayout from "./FormLayout";
import { housingOptions, housingTypeOptions } from "./options";

export interface LocationHousingProps extends LocationCreateProps {}

const LocationHousing: FC<LocationHousingProps> = ({ form, updateForm }) => {
  const [newRule, setNewRule] = useState("");

  const renderRadio = (
    name: string,
    id: string,
    label: string,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center">
        <input
          defaultChecked={defaultChecked}
          id={id + name}
          name={name}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderNoInclude = (text: string) => {
    return (
      <div className="flex items-center justify-between py-3">
        <span className="text-neutral-600 dark:text-neutral-400 font-medium">
          {text}
        </span>
        <i
          onClick={() => removeRule(text)}
          className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"
        ></i>
      </div>
    );
  };

  function handleChange(e: any) {
    updateForm && updateForm(e);
  }

  const housing = getValue(form.housing);
  const housingCost = getValue(form.housingCost);
  const additionalRules = renderTagValue(form.additionalRules);

  function handleAdditionalRules() {
    const currentRules = additionalRules;
    // form.additionalRules
    //   ? Array.isArray(form.additionalRules)
    //     ? form.additionalRules
    //     : form.additionalRules.split(", ")
    //   : [];

    handleChange({
      id: "additionalRules",
      value: [...currentRules, newRule],
    });
    setNewRule("");
  }

  function removeRule(rule: string) {
    const currentRules = additionalRules;
    handleChange({
      id: "additionalRules",
      value: currentRules.filter((i: string) => i !== rule),
    });
  }

  const housingType = Array.isArray(form.housingType)
    ? form.housingType
    : typeof form.housingType === "string" &&
      form.housingType.split(", ").map((i: string) => ({ label: i, value: i }));

  function getValue(e: string | { [e: string]: string }) {
    if (isObject(e)) {
      return e.value;
    }
    return e;
  }

  function renderTagValue(data: any) {
    if (typeof data === "string") {
      return data
        .split(", ")
        .filter((i) => i)
        .map((i: string) => i);
    }

    if (Array.isArray(data)) {
      return data;
    }

    return [];
  }

  // const additionalRules = Array.isArray(form.additionalRules)
  //   ? form.additionalRules
  //   : typeof form.additionalRules === "string" &&
  //     form.additionalRules.split(", ").map((i: string) => i);

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Housing details</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Give a few details about the housing situation
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="space-y-8">
        <NcInputSelect
          id="housing"
          onChange={handleChange}
          label="Housing options"
          value={getValue(form.housing)}
          options={housingOptions}
        />
        {(housing === "Fully provided" || housing === "Provided at cost") && (
          <NcInputSelect
            multiSelect
            id="housingType"
            onChange={handleChange}
            label="Type of housing"
            value={housingType}
            options={housingTypeOptions}
            defaultValue={housingType}
          />
        )}
        {housing === "Provided at cost" && (
          <NcInputSelect
            type="text"
            placeholder="$100-$200/month"
            id="housingCost"
            onChange={({ target }) => handleChange(target)}
            label="Housing price range"
            value={housingCost}
            options={[]}
          />
        )}

        {(housing === "Fully provided" || housing === "Provided at cost") && (
          <>
            <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
            <span className="block text-lg font-semibold">
              Additional rules
            </span>
            {additionalRules && (
              <div className="flow-root">
                <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                  {additionalRules?.map((i: string) => renderNoInclude(i))}
                </div>
              </div>
            )}
            <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
              <Input
                value={newRule}
                onChange={(e) => setNewRule(e.target.value)}
                className="!h-full"
                placeholder="No smoking..."
              />
              <ButtonPrimary
                onClick={handleAdditionalRules}
                className="flex-shrink-0"
              >
                <i className="text-xl las la-plus"></i>
                <span className="ml-3">Add rule</span>
              </ButtonPrimary>
            </div>
          </>
        )}
      </div>
      <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
      <span className="block text-lg font-semibold">Housing Description</span>
      <Textarea
        placeholder="Add a description of the housing situation..."
        id="housingDescription"
        value={form.housingDescription}
        onChange={(e) => updateForm && updateForm(e.target)}
      />
    </>
  );
};

export default LocationHousing;

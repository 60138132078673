import React from "react";
import twFocusClass from "utils/twFocusClass";
import { ButtonCircleProps } from "./ButtonCircle";

export interface ButtonCircleSecondaryProps extends ButtonCircleProps {}

const ButtonCircleSecondary: React.FC<ButtonCircleSecondaryProps> = ({
  className = " ",
  size = " w-9 h-9 ",
  ...args
}) => {
  const colors =
    "dark:text-neutral-300 dark:bg-neutral-900 text-neutral-700 border-neutral-200 disabled:bg-opacity-70 bg-white dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800";
  return (
    <button
      className={
        `ttnc-ButtonCircleSecondary flex items-center justify-center rounded-full !leading-none border ${colors} ${className} ${size} ` +
        twFocusClass(true)
      }
      {...args}
    />
  );
};

export default ButtonCircleSecondary;

import { XMarkIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { FC, useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { LocationCreateProps } from ".";
import { isObject } from "lodash";
import { useDispatch } from "react-redux";
import { removeFileFromLocation } from "redux/reducers/locations/locationSlice";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Toggle from "shared/Toggle/Toggle";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

export interface LocationPhotosProps extends LocationCreateProps {}

const LocationPhotos: FC<LocationPhotosProps> = ({
  form,
  updateForm,
  setFormInit,
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [covImgStore, setCovImgStore] = useState<{ [key: string]: any }>({});
  const [imgsStore, setImgsStore] = useState<any[]>([]);
  const [stringCoverImg, setStringCoverImg] = useState<string>("");
  const [stringImgs, setStringImgs] = useState<string>("");

  function setToggled(id: string, value: boolean) {
    let imgType;
    let newImgs;
    if (id === "coverToggled") {
      imgType = "coverImage";
      console.log(covImgStore);
      if (!value) {
        newImgs = covImgStore.uri ? covImgStore.uri : "";
      } else {
        newImgs = covImgStore?.url ? [covImgStore] : [];
      }
    } else {
      imgType = "images";
      if (!value) {
        newImgs =
          imgsStore.length > 0 ? imgsStore.filter((i) => i.uri).join("\n") : "";
      } else {
        newImgs = imgsStore.length > 0 ? imgsStore.filter((i) => i.url) : [];
      }
    }
    setFormInit && setFormInit({ [id]: value, [imgType]: newImgs });
  }

  useEffect(() => {
    setCovImgStore(form.coverImage);
    setImgsStore(form.images);

    if (
      form.coverImage?.url ||
      form.coverImage?.file ||
      form.images?.[0]?.url ||
      form.images?.[0]?.file
    ) {
      setFormInit &&
        setFormInit({
          coverToggled: !!form.coverImage?.url || !!form.coverImage?.file,
          imgsToggled: !!form.images?.[0]?.url || !!form.images?.[0]?.file,
        });
    }
    if (Array.isArray(form.images) && form.images[0]?.uri) {
      setStringImgs(form.images.map((i: any) => i.uri).join("\n"));
    }
  }, [form._id]);

  function handleCoverImg(e: any) {
    updateForm && updateForm({ id: "coverImage", value: e });
  }

  async function handleImages(e: any, id: any) {
    updateForm && updateForm({ id: "images", value: e });
  }

  // Handles text area cover image
  function handleStringCoverImg(e: any) {
    // Set new coverimage
    setStringCoverImg(e.value);

    // Replace string back up with new image
    setCovImgStore({ uri: e.value });

    // Updates string image to array and sets form.coverImage
    const value = [{ uri: e.value }];
    updateForm && updateForm({ id: "coverImage", value });
  }

  // Handle textarea images
  function handleStringImgs(e: any) {
    // Sets string value in textarea
    setStringImgs(e.value);

    // Parses url by line
    const stringArr = e.value.split("\n");

    // Filters empty values and converts string array to object array
    const value = stringArr
      .filter((i: string) => {
        return i;
      })
      .map((e: string) => ({ uri: e }));

    // Update images in form to use string images
    updateForm && updateForm({ id: "images", value });
  }

  async function removeImage(e: any, keyId: string, idx?: number) {
    // Check if image has a db _id
    if (e._id) {
      // Remove file from location and delete
      const res = await dispatch(
        removeFileFromLocation({ data: { ...e, key: keyId }, id: params.id })
      ).unwrap();

      // Show Toast
      if (res.status === "success") {
        toast(res.message, {
          type: res.status,
          toastId: "REMOVE-IMG-" + e._id,
        });
      }
    }
  }

  function renderCoverImg(covImg: any) {
    if (form.coverToggled) {
      if (Array.isArray(covImg)) {
        return covImg.filter((i: any) => !i.uri);
      }
      if (isObject(covImg)) {
        return [covImg].filter((i: any) => !i.uri);
      }
      return [];
    } else {
      if (Array.isArray(covImg)) {
        return covImg[0]?.uri;
      }
      return stringCoverImg || covImg?.uri;
    }
  }

  function renderImgs(imgs: any): any[] {
    if (form.imgsToggled) {
      if (Array.isArray(imgs)) {
        return imgs.filter((i: any) => !i.uri);
      }
      if (isObject(imgs)) {
        return [imgs].filter((i: any) => !i.uri);
      }
      return [];
    } else {
      if (Array.isArray(imgs)) {
        return imgs.filter((i: any) => i.uri).map((i: any) => i.uri);
        // .join("\n");
      }
      return [stringImgs]; // || imgs?.uri;
    }
  }

  const cI = renderCoverImg(form.coverImage);
  const imgs = renderImgs(form.images);

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Pictures of the location</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          A few photos to help show off your location.
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="space-y-8">
        <div>
          <div className="flex items-center justify-between">
            <span className="text-lg font-semibold">Cover image</span>
            <Toggle
              id="toggle-image-type"
              label="Upload photo"
              onChange={(e) => {
                typeof cI === "string" && setStringCoverImg(cI);
                setToggled("coverToggled", e);
              }}
              checked={form.coverToggled}
              size="sm"
            />
          </div>
          <div className="mt-5 ">
            {form.coverToggled ? (
              <ImageUploading
                value={cI}
                onChange={handleCoverImg}
                maxNumber={1}
                dataURLKey="url"
                acceptType={["jpg", "jpeg", "png"]}
              >
                {(props) => (
                  <UploadLayout
                    id="coverImage"
                    removeImg={removeImage}
                    {...props}
                  />
                )}
              </ImageUploading>
            ) : (
              <Input
                id="coverImage"
                placeholder="Cover Image URL"
                value={cI}
                onChange={({ target }) => handleStringCoverImg(target)}
              />
            )}
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <div className="">
              <span className="text-lg font-semibold">
                Pictures of the place
              </span>
              {!form.imgsToggled && (
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  One url per row
                </span>
              )}
            </div>
            <Toggle
              id="toggle-image-type"
              label="Upload photos"
              onChange={(e) => {
                // typeof cI === "string" && setStringImgs(imgs);
                setToggled("imgsToggled", e);
              }}
              checked={form.imgsToggled}
              size="sm"
            />
          </div>
          <div className="mt-5 ">
            {form.imgsToggled ? (
              <ImageUploading
                multiple
                value={imgs}
                onChange={handleImages}
                maxNumber={8}
                dataURLKey="url"
                acceptType={["jpg", "jpeg", "png"]}
              >
                {(props) => (
                  <UploadLayout
                    id="images"
                    removeImg={removeImage}
                    multiple
                    {...props}
                  />
                )}
              </ImageUploading>
            ) : (
              // ""
              <Textarea
                id="images"
                placeholder="One url per line"
                value={stringImgs}
                onChange={({ target }) => handleStringImgs(target)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationPhotos;

interface UploadLayoutType {
  imageList: any;
  onImageUpload: any;
  onImageRemoveAll: any;
  onImageUpdate: any;
  onImageRemove: any;
  isDragging: any;
  dragProps: any;
  errors: any;
  id: string;
  multiple?: boolean;
  removeImg?: (e: any, id: string, index?: number) => void;
}

const UploadLayout: FC<UploadLayoutType> = ({
  imageList,
  onImageUpload,
  onImageRemoveAll,
  onImageUpdate,
  onImageRemove,
  isDragging,
  dragProps,
  errors,
  multiple,
  removeImg,
  id,
}) => {
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (errors) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [errors]);

  function handleRemove(image: any, index: number) {
    removeImg && removeImg(image, id, index);
    return onImageRemove(index);
  }

  return isDragging || imageList.length === 0 ? (
    <div
      onClick={onImageUpload}
      className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-600 border-dashed rounded-md"
      {...dragProps}
    >
      <div className="space-y-1 text-center">
        <svg
          className="mx-auto h-12 w-12 text-neutral-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        <div className="flex justify-center text-sm text-neutral-600 dark:text-neutral-300">
          {isDragging ? (
            <span className="relative cursor-pointer rounded-md font-medium">
              Drop here
            </span>
          ) : (
            <>
              <span className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
                Upload a file
              </span>
              <p className="pl-1">or drag and drop</p>
            </>
          )}
        </div>
        <p className="text-xs text-neutral-500 dark:text-neutral-400">
          PNG, JPG, JPEG up to 10MB
        </p>
      </div>
    </div>
  ) : (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-5 gap-2">
        {(Array.isArray(imageList) ? imageList : [imageList]).map(
          (image: any, index: number) => {
            return (
              <div key={index} className="w-28 h-28 border relative rounded-xl">
                <div
                  className="absolute inset-0 bg-cover bg-center z-0 rounded-xl"
                  style={{
                    backgroundImage: `url('${image.url}')`,
                  }}
                ></div>
                <div className="opacity-0 hover:opacity-100 duration-300 rounded-xl absolute inset-0 z-10 flex justify-end text-6xl text-white font-semibold">
                  <XMarkIcon
                    onClick={() => handleRemove(image, index)}
                    className="w-6 h-6 z-30 stroke-2 m-2 cursor-pointer"
                  />
                  <div className="bg-black opacity-30 rounded-xl w-full h-full absolute" />
                </div>
              </div>
            );
          }
        )}
        {multiple && (
          <div
            onClick={onImageUpload}
            className="w-28 h-28 border relative rounded-xl"
          >
            <div className="absolute inset-0 bg-cover bg-center z-0 rounded-xl"></div>
            <div className="opacity-0 hover:opacity-100 cursor-pointer duration-300 rounded-xl absolute inset-0 z-10 flex justify-end text-6xl text-white font-semibold">
              <div className="bg-black opacity-10 rounded-xl w-full h-full absolute" />
            </div>
            <div className="flex flex-col items-center z-30 w-full mt-5 text-gray-700 ">
              <PlusIcon className="w-10 h-10" />
              <span className="text-sm">Add Image</span>
            </div>
          </div>
        )}
      </div>
      {showError && errors && (
        <div className="mt-2 text-red-600">
          {errors.maxNumber && (
            <span>Number of selected images exceed maxNumber</span>
          )}
          {errors.acceptType && (
            <span>Your selected file type is not allow</span>
          )}
          {errors.maxFileSize && (
            <span>Selected file size exceed maxFileSize</span>
          )}
          {errors.resolution && (
            <span>Selected file does not match the desired resolution</span>
          )}
        </div>
      )}
    </>
  );
};

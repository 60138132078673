import React, { FC, ReactNode, useEffect } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  resetLocations,
} from "redux/reducers/locations/locationsSlice";
import { LocationDataProps, MediaType } from "types";
import LocationCard from "./LocationCardVertical";
import { ReducersType } from "redux/types";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

//
export interface SectionGridFeaturedLocationsProps {
  // data?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturedLocations: FC<SectionGridFeaturedLocationsProps> = ({
  // data = DEMO_DATA,
  gridClass = "",
  heading = "Featured locations to work",
  subHeading = "Top rated locations to work",
  headingIsCenter,
  tabs = [],
}) => {
  const dispatch = useDispatch();
  const { data, status } = useSelector(
    (state: ReducersType) => state.locations
  );

  function getLocations(init?: boolean) {
    let page = init ? 1 : data.currentPage + 1;

    if (!data.hasNextPage) {
      page = 1;
    }
    if (data)
      dispatch(
        fetchLocations({
          limit: 12,
          page,
          active: true,
          status: "published",
        })
      );
  }

  useEffect(() => {
    if (status === "idle") {
      getLocations(true);
    }
  }, [status]);

  useEffect(() => {
    return () => dispatch(resetLocations());
  }, []);

  const renderCard = (item: LocationDataProps) => {
    let location = { ...item, images: item.images || [] };
    let images = [...location.images];
    location.href = "/locations/" + location._id;
    let coverImage = location.coverImage;

    if (Array.isArray(images) && location.coverImage) {
      images.unshift(coverImage);
    } else {
      images = [""];
    }

    location.images = [...images].map((i) =>
      typeof i === "string" ? i : i.uri || i.url
    );
    return <LocationCard key={location._id} data={{ ...location }} />;
  };

  return (
    <div className="nc-SectionGridFeaturedLocations relative">
      <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickRefresh={getLocations}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {data.locations?.map((stay: LocationDataProps) => renderCard(stay))}
      </div>
    </div>
  );
};

export default SectionGridFeaturedLocations;

const isDev = process.env.NODE_ENV === "development";
const isBeta = window.location.href.includes("beta");

const defaultSettings = {
  ROOT_URL: isDev
    ? "http://localhost:8080/api/v1"
    : `https://${isBeta ? "beta.api" : "api"}.upseasonal.com/api/v1`,
};

export default defaultSettings;

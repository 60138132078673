import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import Input from "shared/Input/Input";

export interface OptionsProps {
  label: any;
  value: any;
  onClick?: (data: OptionsProps) => void;
  id?: string;
}

export interface SelectProps {
  className?: string;
  sizeClass?: string;
  options?: OptionsProps[];
  placeholder?: string | undefined;
  id?: string;
  children?: any;
  onChange?: (data: OptionsProps) => void;
  value?: any;
  search?: boolean;
  label?: any;
  defaultValue?: any;
}

const Select: FC<SelectProps> = ({
  className = "",
  sizeClass = "h-11",
  children,
  options,
  placeholder = "Select an option",
  onChange,
  id,
  ...args
}) => {
  const defaultValue =
    args.defaultValue || typeof args.value === "string"
      ? {
          label: args.value,
          value: args.value,
        }
      : args.value;
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState<OptionsProps>(
    defaultValue || {
      label: placeholder,
      value: "",
    }
  );

  useEffect(() => {
    args.value && setSelected(defaultValue);
    !args.value &&
      setSelected(
        defaultValue || {
          label: placeholder,
          value: "",
        }
      );
  }, [args.value]);

  if (!options) {
    return (
      <select
        className={`nc-Select ${sizeClass} ${className} block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
        {...args}
      >
        {children}
      </select>
    );
  }

  const buttonClasses = classNames(
    "nc-Select px-4 py-3 text-sm font-normal w-full flex items-center border",
    "border-neutral-200 focus:border-primary-300 dark:border-neutral-700 rounded-2xl",
    "focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:focus:ring-primary-600 dark:focus:ring-opacity-25",
    "bg-white dark:bg-neutral-900",
    {
      "text-neutral-500 dark:text-neutral-400": placeholder === selected.label,
      "px-0 py-0 border-0": args.search,
    },
    sizeClass,
    className
  );

  function handleSelect(obj: any) {
    setSelected(obj);
    if (id) obj.id = id;
    onChange && onChange(obj);
  }

  function handleSearch(e: any) {
    console.log(e);
  }

  return (
    <div className="w-full">
      <Listbox value={selected} onChange={handleSelect}>
        <div className="relative mt-1">
          <Listbox.Button className={buttonClasses}>
            {args.search ? (
              <>
                <Input
                  ref={inputRef}
                  placeholder={placeholder}
                  value={inputValue}
                  onChange={handleSearch}
                />
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </>
            ) : (
              <>
                <span className="block truncate">
                  {args.label ? args.label : selected.label}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 z-10 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option, i) => {
                return (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 px-4 ${
                        active
                          ? "bg-primary-100 text-primary-900"
                          : "text-gray-900"
                      }`
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        {typeof option.label === "string" ? (
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {option.label}
                          </span>
                        ) : (
                          option.label
                        )}
                        {/* {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null} */}
                      </>
                    )}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Select;

import { MapPinIcon } from "@heroicons/react/24/solid";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import Label from "components/Label/Label";
import { countrySelectOptions } from "constants/countries";
import GoogleMapReact from "google-map-react";
import { isObject } from "lodash";
import React, { FC, useEffect } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { getFullAddress, LocationCreateProps } from ".";
import FormItem from "./FormItem";
import LocationCreateLayout from "./FormLayout";

export interface LocationAddressProps extends LocationCreateProps {}

const LocationAddress: FC<LocationAddressProps> = ({
  form,
  updateForm,
  setFormInit,
}) => {
  // useEffect(() => {
  //   if (isObject(form.address)) {
  //     setFormInit &&
  //       setFormInit({
  //         street: form.address.street,
  //         city: form.address.city,
  //         state: form.address.state,
  //         zipcode: form.address.zipcode,
  //         country: form.address.country,
  //         suite: form.address.suite,
  //       });
  //   }
  // }, [form.address]);

  function handleChange(e: any) {
    updateForm && updateForm(e);
  }

  function renderValue(e: any) {
    let val = "";

    if (form[e]) {
      val = form[e];
    } else {
      val = form?.address?.[e];
    }

    return val;
  }

  return (
    <>
      <h2 className="text-2xl font-semibold">Location address</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* FORM */}
      <div className="space-y-8">
        {/* ITEM */}
        <FormItem label="Street">
          <Input
            id="street"
            value={renderValue("street")}
            onChange={({ target }) => handleChange(target)}
            placeholder="..."
          />
        </FormItem>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
          <FormItem label="City">
            <Input
              id="city"
              value={renderValue("city")}
              onChange={({ target }) => handleChange(target)}
            />
          </FormItem>
          <FormItem label="State">
            <Input
              id="state"
              value={renderValue("state")}
              onChange={({ target }) => handleChange(target)}
            />
          </FormItem>
          <FormItem label="Postal code">
            <Input
              id="zipcode"
              value={renderValue("zipcode")}
              onChange={({ target }) => handleChange(target)}
            />
          </FormItem>
        </div>
        <FormItem label="Country/Region">
          <Select
            id="country"
            value={renderValue("country")}
            onChange={(e: any) => handleChange({ [e.id]: e })}
            options={countrySelectOptions}
          />
        </FormItem>
      </div>
    </>
  );
};

export default LocationAddress;

import { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { LocationCreateProps } from ".";
import FormItem from "./FormItem";
import { locationTypeOptions } from "./options";

export interface LocationInfoProps extends LocationCreateProps {}

const options = [
  { label: "Hotel", value: "Hotel" },
  { label: "Resort", value: "Resort" },
  { label: "Ski Resort", value: "Ski Resort" },
  { label: "Guest Ranch", value: "Guest Ranch" },
  { label: "Bed and Breakfast", value: "Bed and Breakfast" },
];

const LocationInfo: FC<LocationInfoProps> = ({ form, updateForm }) => {
  function handleChange(e: any) {
    updateForm && updateForm(e);
  }

  return (
    <>
      <h2 className="text-2xl font-semibold">Create Location</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* FORM */}
      <div className="space-y-8">
        {/* ITEM */}
        <FormItem
          label="Choose a property type"
          // desc="Hotel: Professional hospitality businesses that usually have a unique style or theme defining their brand and decor"
        >
          <Select
            id="type"
            onChange={(obj) => handleChange({ id: "type", value: obj })}
            value={form.type}
            options={locationTypeOptions}
          />
        </FormItem>
        <FormItem label="Location name" desc="The dispay name for the location">
          <Input
            id="name"
            onChange={({ target }) => handleChange(target)}
            value={form.name}
            placeholder="Location name"
          />
        </FormItem>
      </div>
    </>
  );
};

export default LocationInfo;

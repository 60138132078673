import Label from "components/Label/Label";
import { FC } from "react";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

export interface FeedbackFormProps {
  form: any;
  updateForm: any;
}

const FeedbackForm: FC<FeedbackFormProps> = ({ form, updateForm }) => {
  return (
    <div className="space-y-5">
      <div>
        <Label>Subject</Label>
        <Input id="subject" value={form.subject} onChange={updateForm} />
      </div>
      <div>
        <Label>Body</Label>
        <Textarea id="body" value={form.body} onChange={updateForm} />
      </div>
    </div>
  );
};

export default FeedbackForm;

import { FC, ReactNode, useEffect, useState } from "react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import GoogleMapReact from "google-map-react";
import { Reviews } from "./Reviews";
import MainDetails from "./MainDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocation,
  resetLocation,
} from "redux/reducers/locations/locationSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchReviews,
  resetReviews,
} from "redux/reducers/reviews/reviewsSlice";
import { Dropdown } from "shared/Dropdown/Dropdown";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useAuth } from "hooks/useAuth";
import { AddressType } from "types";
import HousingInfo from "./HousingInfo";
import ImagesHeader from "./ImagesHeader";
import ShowMore from "components/ShowMore/ShowMore";
import Modal from "shared/Modal/Modal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import request from "utils/request";
import { toast } from "react-toastify";

export interface LocationDetailProps {
  className?: string;
  isPreviewMode?: boolean;
}

export const Amenities_demos = [
  { name: "Season", icon: "la-key" },
  { name: "Pets", icon: "la-luggage-cart" },
  { name: "Transportation", icon: "la-shower" },
  { name: "Meals", icon: "la-smoking" },
  { name: "Cell service", icon: "la-snowflake" },
  { name: "Internet Service", icon: "la-spa" },
  { name: "Guest Size", icon: "la-suitcase" },
  { name: "Staff Size", icon: "la-suitcase" },
];

const LocationDetail: FC<LocationDetailProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAuth();

  const [showMore, setShowMore] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string | ReactNode>();
  // const [paginate, setPaginate] = useState({});

  const location = useSelector((state: any) => state.location);

  useEffect(() => {
    if (location.status === "idle") {
      dispatch(fetchLocation({ id: params.id }));
    }
  }, [location.status]);

  useEffect(() => {
    return () => {
      // dispatch(resetReviews());
      dispatch(resetLocation());
    };
  }, []);

  const renderDescription = () => {
    const desc = location.data.description || "";
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-600 dark:text-neutral-300 whitespace-pre-wrap">
          <ShowMore
            length={650}
            className="text-neutral-600 dark:text-neutral-300 whitespace-pre-wrap"
          >
            {desc}
          </ShowMore>
        </div>
      </div>
    );
  };

  function renderCityState(address?: AddressType): string {
    if (address) {
      return `${address.city}, ${address.state}, ${address.country}`;
    }
    return "";
  }

  const renderLocation = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {renderCityState(location.data.address)}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY",
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={15}
              defaultCenter={{
                lat: 55.9607277,
                lng: 36.2172614,
              }}
            >
              <LocationMarker lat={55.9607277} lng={36.2172614} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  async function handleDelete() {
    const res = await request("/locations/" + params.id, {
      method: "delete",
    });

    if (res.data.status === "success") {
      setModalOpen(false);
      navigate("/locations");
      toast(res.data.message, {
        toastId: "locationDeleted",
        type: "success",
      });
    }
  }

  function handleModal() {
    const content = (
      <div>
        Are you sure you want to delete: <strong>{location.data.name}</strong>
      </div>
    );
    setModalContent(content);
    setModalOpen(true);
  }

  function closeModal() {
    setModalContent("");
    setModalOpen(false);
  }
  const buttonSize = "px-2 py-2 sm:px-4";

  const modalFooter = (
    <div className="space-x-2">
      <ButtonSecondary
        sizeClass={buttonSize}
        title="Cancel"
        onClick={closeModal}
      />
      <ButtonPrimary
        sizeClass={buttonSize}
        title="Delete"
        onClick={handleDelete}
      />
    </div>
  );

  const dropData = [
    {
      name: "Edit",
      onClick: (data: any) => navigate("/locations/" + params.id + "/edit"),
    },
    { name: "Delete", onClick: (data: any) => handleModal() },
  ];

  const housingInfo = {
    housing: location.data?.housing,
    housingType: location.data?.housingType,
    housingCost: location.data?.housingCost,
    additionalRules: location.data?.additionalRules,
    housingDescription: location.data?.housingDescription,
  };

  return (
    <div
      className={`ListingDetailPage nc-LocationDetail ${className}`}
      data-nc-id="LocationDetail"
    >
      {user.active && user.authority === "superAdmin" && (
        <div className="container relative z-10 my-4 flex flex-col lg:flex-row ">
          <div className="w-full">
            <div className="flex items-center justify-end">
              {/* <div className="flex bg-yellow-100 border rounded-xl border-t-yellow-300 p-3">
                This is an admin view, to see the public view
                <div className="hover:underline cursor-pointer ml-1 text-blue-500">
                  click here
                </div>
              </div> */}
              <div>
                <Dropdown
                  trigger={
                    <ButtonSecondary
                      sizeClass="py-2 px-3"
                      title={
                        <span className="flex items-center">
                          Actions <ChevronDownIcon className="w-4 h-4" />
                        </span>
                      }
                    />
                  }
                  data={dropData}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Images */}
      <ImagesHeader
        coverImage={location.data?.coverImage}
        images={location.data?.images}
      />
      <main className="container relative z-0 mt-11 flex flex-col lg:flex-row ">
        <div className="w-full space-y-8 lg:space-y-10">
          <MainDetails info={location.data} />
          {renderDescription()}
          <HousingInfo data={housingInfo} />
          <Reviews />
          {/* {renderLocation()} */}
        </div>
      </main>
      <Modal
        visible={modalOpen}
        setVisible={setModalOpen}
        title="Delete this location?"
        content={modalContent}
        size="md"
        footer={modalFooter}
      />

      {/* STICKY FOOTER MOBILE */}
      {/* NOT USED, BUT POSSIBLE FEATURE?? */}
      {/* {!isPreviewMode && <MobileFooterSticky />} */}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-16 lg:py-16" />
        // <div className="container py-24 lg:py-32">
        //   DO YOU LIKE THIS
        //   <div className="relative py-16">
        //     <BackgroundSection />
        //     <SectionSliderNewCategories
        //       heading="Explore by scenery"
        //       subHeading="Explore work locations by scenery"
        //       categoryCardType="card5"
        //       itemPerRow={5}
        //       sliderStyle="style2"
        //       uniqueClassName={"LocationDetail1"}
        //     />
        //   </div>

        //   <SectionSubscribe2 className="pt-24 lg:pt-32" />
        // </div>
      )}
    </div>
  );
};

export default LocationDetail;

import { FC } from "react";
import googleSvg from "images/Google.svg";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import useForm from "hooks/useForm";
import { useAuth } from "hooks/useAuth";
import validator from "validator";
import Progress from "shared/Progress/Progress";
import { renderValidations } from "hooks/useForm";
import LoginLayout from "layouts/LoginLayout";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
    id: "google",
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const { register, loading } = useAuth();
  const { form, updateForm, setFormInit } = useForm({
    validations: renderValidations([
      {
        id: "email",
        validations: { required: true, email: true },
      },
    ]),
  });

  function handleChange(e: any) {
    const { id, value } = e.target;
    updateForm({ id, value });
  }

  function handlePasswordChange({ target: { id, value } }: any) {
    let color;
    const strength = validator.isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      returnScore: true,
      pointsPerUnique: 5,
      pointsPerRepeat: 5,
      pointsForContainingLower: 15,
      pointsForContainingUpper: 15,
      pointsForContainingNumber: 15,
      pointsForContainingSymbol: 15,
    });

    let error;

    if (validator.isAlpha(value)) {
      error = "Password must contain at least 1 number";
    }
    if (!/[A-Z]/.test(value)) {
      error = "Password must contain at least 1 uppercase";
    }
    if (value.length < 8) {
      error = "Password must be longer than 8 characters";
    }
    if (!value) {
      error = "Please provide a password";
    }
    if (error && strength >= 100) {
      color = "red";
    }

    setFormInit({
      [id]: value,
      strength,
      error,
      color,
      passwordValid: !error && strength >= 100,
    });
  }

  function handleSubmit() {
    const { email, password } = form;
    register({ email, password });
  }

  return (
    <LoginLayout
      title="Sign Up"
      footer={
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Already have an account? {` `}
          <Link className="text-primary-600" to="/login">
            Sign in
          </Link>
        </span>
      }
      buttonDisabled={loading || !form.passwordValid}
      buttonTitle="Sign up"
      onSubmit={handleSubmit}
      loading={loading}
    >
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Email address
        </span>
        <Input
          id="email"
          value={form.email}
          onChange={handleChange}
          type="email"
          placeholder="example@example.com"
          className="mt-1"
        />
      </label>
      <label className="block">
        <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
          Password
        </span>
        <Input
          id="password"
          type="password"
          className="mt-1"
          value={form.password}
          onChange={handlePasswordChange}
        />
      </label>
      {"password" in form && (
        <>
          <Progress color={form.color} percent={form.strength} size="sm" />
          {form.error && <div className="text-gray-700">{form.error}</div>}
        </>
      )}
    </LoginLayout>
  );
};

export default PageSignUp;

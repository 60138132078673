import NcInputSelect from "components/NcInputSelect/NcInputSelect";
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";
import Input from "shared/Input/Input";
import { LocationCreateProps } from ".";
import { experienceOptions } from "./options";

export interface LocationTagsProps extends LocationCreateProps {}

const popularTags = ["Tag 1", "Tag 2", "Tag 3", "Tag 4", "Tag 5"];

const LocationTags: FC<LocationTagsProps> = ({
  form,
  updateForm,
  setFormInit,
}) => {
  const [newAddTag, setNewAddTag] = useState("");
  const [newTag, setNewTag] = useState("");

  const renderNoInclude = (text: string) => {
    return (
      <div className="flex items-center justify-between py-3">
        <span className="text-neutral-600 dark:text-neutral-400 font-medium">
          {text}
        </span>
        <i
          onClick={() => removeAddTag(text)}
          className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"
        ></i>
      </div>
    );
  };

  function renderTagValue(data: any) {
    if (typeof data === "string") {
      return data
        .split(", ")
        .filter((i) => i)
        .map((i: string) => i);
    }

    if (Array.isArray(data)) {
      return data;
    }

    return [];
  }

  const additionalTags = renderTagValue(form.additionalTags);
  // const tags = renderTagValue(form.tags);

  function handleChange(e: any) {
    updateForm && updateForm(e);
  }

  function handleAdditionalTags() {
    const currentTags = additionalTags || [];

    setFormInit &&
      setFormInit({
        additionalTags: [...currentTags, newAddTag],
      });
    setNewAddTag("");
  }

  function removeAddTag(tag: string) {
    const currentTags = additionalTags;
    handleChange({
      id: "additionalTags",
      value: currentTags.filter((i: string) => i !== tag),
    });
  }

  function setTags(e: any) {
    const tags = form.tags || [];

    const index = tags && tags.findIndex((i: string) => i === e.value);
    if (index > -1) {
      tags.splice(index, 1);
    } else {
      tags.push(e.value);
    }

    handleChange({ id: "tags", value: tags });
  }

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Tags </h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Optional, but these tags will help your location show up in searches
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="space-y-8">
        <div>
          <NcInputSelect
            id="experience"
            onChange={handleChange}
            label="Experience"
            value={form.experience}
            options={experienceOptions}
          />
        </div>
        {/* <div>
          <label className="text-lg font-semibold" htmlFor="">
            Popular tags
          </label>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {popularTags.map((item, i) => {
              const currentTags = tags;
              // typeof form.tags === "string"
              //   ? form.tags.split(", ")
              //   : form.tags;

              const isChecked =
                currentTags &&
                currentTags.findIndex((i: string) => i === item) > -1;

              return (
                <Checkbox
                  id={`tags-${i}`}
                  name={item}
                  checked={isChecked}
                  value={item}
                  onChange={setTags}
                  label={item}
                  defaultChecked={isChecked}
                />
              );
            })}
          </div>
        </div> */}
        <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>
        <span className="block text-lg font-semibold">Additional tags</span>

        {additionalTags && (
          <div className="flow-root">
            <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
              {additionalTags?.map((i: string) => renderNoInclude(i))}
            </div>
          </div>
        )}
        <div className="flex flex-col sm:flex-row sm:justify-between space-y-3 sm:space-y-0 sm:space-x-5">
          <Input
            value={newAddTag}
            onChange={(e) => setNewAddTag(e.target.value)}
            className="!h-full"
            placeholder="West coast"
          />
          <ButtonPrimary
            onClick={handleAdditionalTags}
            className="flex-shrink-0"
            disabled={!(newAddTag.length > 0)}
          >
            <i className="text-xl las la-plus"></i>
            <span className="ml-3">Add tag</span>
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};

export default LocationTags;

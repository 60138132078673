import { useEffect, useState } from "react";
import { isObject } from "../utils";
import {
  FormValidationTypes,
  UseFormOptionsProps,
  UseFormReturnProps,
} from "./types";

const useForm = (options?: any): UseFormReturnProps => {
  const [form, setForm] = useState<{ [key: string]: any }>({});
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [hasBeenInit, setHasBeenInit] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    if (isFormValid()) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  function updateForm(obj: any, options: any) {
    if (options) {
      if (options.formArray) {
        let init: any = {};
        Object.keys(obj.value).forEach((key, i) => {
          if (isObject(obj.value[key])) {
            Object.keys(obj.value[key]).forEach((item, i) => {
              init[item] = true;
            });
          } else {
            init[key] = true;
          }
        });
        setHasBeenInit({ ...hasBeenInit, [obj.id]: true, ...init });
      }
      if (options.valid) {
        setIsValid(true);
      }
    }
    if (!form[obj.id]) {
      setHasBeenInit({ ...hasBeenInit, [obj.id]: true });
    }

    setForm({ ...form, [obj.id]: obj.value });
  }

  function setFormInit(obj: any, options: any) {
    if (options) {
      if (options.setErrors) {
        let init: any = {};
        Object.keys(obj).forEach((key) => (init[key] = true));
        setHasBeenInit(init);
      }

      if (options.valid) {
        setIsValid(true);
      }

      if (options.reset) {
        return setForm({ ...obj });
      }
    }

    return setForm({ ...form, ...obj });
  }

  function clearForm(id?: string) {
    const ids = id && id.split(", ");
    if (Array.isArray(ids)) {
      if (ids.length > 1) {
        ids.forEach((item: string) => {
          delete form[item];
        });
        setForm({ ...form });
        return;
      } else {
        delete form[ids[0]];
        setForm({ ...form });
        return;
      }
    }

    return setForm({});
  }

  function valueIsEmpty(value: any) {
    if (!value) return true;

    if (value instanceof Object && Object.keys(value).length === 0) {
      return true;
    }

    if (Array.isArray(value) && value.length < 1) {
      return true;
    }

    if (typeof value === "string" && value === "") {
      return true;
    }

    return false;
  }

  function isFormValid() {
    let validations = options?.validations;

    if (validations) {
      let valid = true;
      let newErrors: any = {};

      Object.keys(validations).forEach((key) => {
        let renderError = hasBeenInit[key];
        const value = form[key];
        const validation = validations[key];

        if (valueIsEmpty(renderError)) {
          valid = false;
          return;
        }

        // Regex
        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        // Custom
        const custom = validation?.custom;
        const customIsValid = custom?.isValid(value, {
          validations: validations[key],
          form,
          isFormValid,
          validateEmail,
          valueIsEmpty,
          hasBeenInit,
        });
        if (custom?.isValid) {
          if (isObject(customIsValid) && !customIsValid.isValid) {
            valid = false;
            newErrors = { ...newErrors, ...customIsValid.errors };
          }
        }

        // Email
        if (validation?.validations?.email && !validateEmail(value)) {
          valid = false;
          newErrors[key] = "Must be a valid email";
        }

        // Required
        if (
          (validation?.required ||
            validation?.validations?.email ||
            validation?.required?.value) &&
          valueIsEmpty(value)
        ) {
          valid = false;
          newErrors[key] =
            validation?.required?.message || "This field is required";
        }
      });

      if (!valid) {
        setErrors(newErrors);
        return false;
      }
    }

    setErrors({});
    return true;
  }

  return { form, updateForm, setFormInit, isValid, errors, clearForm };
};

export default useForm;

export function renderValidations(arr: FormValidationTypes[]) {
  const res: any = {};
  arr.forEach((item) => {
    if (isObject(item)) {
      const id = item.id;
      return (
        id &&
        (item.required || item.custom || item.pattern || item.validations) &&
        (res[id] = {
          ...item,
        })
      );
    }
  });

  return res;
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const seasonOptions = [
  { label: "Spring", value: "Spring" },
  { label: "Summer", value: "Summer" },
  { label: "Fall", value: "Fall" },
  { label: "Winter", value: "Winter" },
  { label: "Year round", value: "Year round" },
  { label: "Year round availability", value: "Year round availability" },
];
export const housingOptions = [
  { label: "Fully provided", value: "Fully provided" },
  { label: "Provided at cost", value: "Provided at cost" },
  { label: "Not provided", value: "Not provided" },
];
export const housingTypeOptions = [
  {
    label: "Dormitory style 1-4 roomates",
    value: "Dormitory style 1-4 roomates",
  },
  { label: "Shared Rooms 1-3 Roomates", value: "Shared Rooms 1-3 Roomates" },
  { label: "Single room options", value: "Single room options" },
  { label: "Couple housing", value: "Couple housing" },
];
export const transportationOptions = [
  { label: "Full shuttle service", value: "Full shuttle service" },
  {
    label: "Shuttle but difficult or unreliable",
    value: "Shuttle but difficult or unreliable",
  },
  { label: "Need personal vehicle", value: "Need personal vehicle" },
  { label: "Within walking distance", value: "Within walking distance" },
];
export const staffSizeOptions = [
  { label: "Less than 25", value: "Less than 25" },
  { label: "25 - 100", value: "25 - 100" },
  { label: "100 - 300", value: "100 - 300" },
  { label: "300 - 500", value: "300 - 500" },
  { label: "500 +", value: "500 +" },
];
export const mealsOptions = [
  {
    label: "EDR(Employee dining room) full service",
    value: "EDR(Employee dining room) full service",
  },
  { label: "EDR limited service", value: "EDR limited service" },
  { label: "Stipen for food", value: "Stipen for food" },
  {
    label: "Food provided from employer",
    value: "Food provided from employer",
  },
  { label: "Not provided", value: "Not provided" },
];
export const cellServiceOptions = [
  { label: "Great", value: "Great" },
  { label: "Good", value: "Good" },
  { label: "Moderate", value: "Moderate" },
  { label: "Bad", value: "Bad" },
  { label: "No Service", value: "No Service" },
];
export const internetAccessOptions = [
  { label: "Great", value: "Great" },
  { label: "Good", value: "Good" },
  { label: "Moderate", value: "Moderate" },
  { label: "Bad", value: "Bad" },
  { label: "No Service", value: "No Service" },
]; //option to fill in best carriers
export const petFriendlyOptions = [
  { label: "Accepts pets", value: "Accepts pets" },
  {
    label: "Pet availability if requested",
    value: "Pet availability if requested",
  },
  { label: "No pets allowed", value: "No pets allowed" },
];
export const guestSizeOptions = [
  { label: "Less than 25", value: "Less than 25" },
  { label: "25 - 100", value: "25 - 100" },
  { label: "100 - 300", value: "100 - 300" },
  { label: "300 - 500", value: "300 - 500" },
  { label: "500 +", value: "500 +" },
];
export const experienceOptions = [
  { value: "Desert", label: "Desert" },
  { value: "Forest", label: "Forest" },
  { value: "Mountains", label: "Mountains" },
  { value: "Plains", label: "Plains" },
  { value: "River", label: "River" },
  { value: "Sea Side", label: "Sea Side" },
];
export const locationTypeOptions = [
  { value: "Hotel", label: "Hotel" },
  { value: "Resort", label: "Resort" },
  { value: "Ski Resort", label: "Ski Resort" },
  { value: "Guest Ranch", label: "Guest Ranch" },
  { value: "Marina", label: "Marina" },
  { value: "Hot Springs", label: "Hot Springs" },
  { value: "Boat Club", label: "Boat Club" },
  { value: "Restaurant/Bar", label: "Restaurant/Bar" },
  { value: "Glamping Hotel", label: "Glamping Hotel" },
  { value: "Fishing Lodge", label: "Fishing Lodge" },
  { value: "Dude Ranch", label: "Dude Ranch" },
  { value: "Lodge", label: "Lodge" },
  { value: "Hunting Lodge", label: "Hunting Lodge" },
  { value: "Cruise Line", label: "Cruise Line" },
];
export const regionOptions = [
  { value: "West Coast", label: "West Coast" },
  { value: "Pacific North West", label: "Pacific North West" },
  { value: "South West", label: "South West" },
  { value: "Mid West", label: "Mid West" },
  { value: "South", label: "South" },
  { value: "East Coast", label: "East Coast" },
  { value: "South", label: "South" },
];
export const featureOptions = [
  { value: "Appalacian Mountains", label: "Appalacian Mountains" },
  { value: "Yosemite", label: "Yosemite" },
  { value: "Yellowstone", label: "Yellowstone" },
  { value: "Rocky Mountains", label: "Rocky Mountains" },
];

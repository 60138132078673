import NcInputSelect from "components/NcInputSelect/NcInputSelect";
import React, { FC } from "react";
import Select from "shared/Select/Select";
import { LocationCreateProps } from ".";
import FormItem from "./FormItem";
import LocationCreateLayout from "./FormLayout";
import {
  cellServiceOptions,
  guestSizeOptions,
  internetAccessOptions,
  mealsOptions,
  petFriendlyOptions,
  seasonOptions,
  staffSizeOptions,
  transportationOptions,
} from "./options";

export interface LocationDetailsProps extends LocationCreateProps {}

const LocationDetails: FC<LocationDetailsProps> = ({
  form,
  setFormInit,
  updateForm,
}) => {
  function handleChange(e: any) {
    updateForm && updateForm(e);
  }

  const season = Array.isArray(form.season)
    ? form.season
    : typeof form.season === "string" &&
      form.season.split(", ").map((i: string) => ({ label: i, value: i }));
  const meals = Array.isArray(form.meals)
    ? form.meals
    : typeof form.meals === "string" &&
      form.meals.split(", ").map((i: string) => ({ label: i, value: i }));
  return (
    <>
      <h2 className="text-2xl font-semibold">Location details</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="space-y-8">
        <NcInputSelect
          multiSelect
          id="season"
          onChange={(e) => handleChange({ id: e.id, value: e.value })}
          label="Season"
          value={season}
          options={seasonOptions}
          defaultValue={season}
        />
        <NcInputSelect
          id="transportation"
          onChange={handleChange}
          label="Transportation"
          value={form.transportation}
          options={transportationOptions}
        />
        <NcInputSelect
          id="staffSize"
          onChange={handleChange}
          label="Staff Size"
          value={form.staffSize}
          options={staffSizeOptions}
        />
        <NcInputSelect
          multiSelect
          id="meals"
          onChange={handleChange}
          label="Meals"
          value={meals}
          options={mealsOptions}
          defaultValue={meals}
        />
        <NcInputSelect
          id="cellService"
          onChange={handleChange}
          label="Cell Service"
          value={form.cellService}
          options={cellServiceOptions}
        />
        <NcInputSelect
          id="internetAccess"
          onChange={handleChange}
          label="Internet Access"
          value={form.internetAccess}
          options={internetAccessOptions}
        />
        <NcInputSelect
          id="petFriendly"
          onChange={handleChange}
          label="Pet Friendly"
          value={form.petFriendly}
          options={petFriendlyOptions}
        />
        <NcInputSelect
          id="guestSize"
          onChange={handleChange}
          label="Guest Size"
          value={form.guestSize}
          options={guestSizeOptions}
        />
      </div>
    </>
  );
};

export default LocationDetails;

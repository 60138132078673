// import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import React, { FC, useEffect, useMemo } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import { Link } from "react-router-dom";
import { MediaType } from "types";
import Glide from "shared/Glide";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[] | MediaType[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
  href = "/listing-stay-detail",
}) => {
  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {galleryImgs.map((_, i) => (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i}
            data-glide-dir={`=${i}`}
          />
        ))}
      </div>
    );
  };

  const renderSliderGallery = (item: any) => {
    return (
      <Link to={href} className={`block ${ratioClass}`}>
        <NcImage src={typeof item === "string" ? item : item?.url} />
      </Link>
    );
  };

  const controls = (
    <>
      {/* DOTS */}
      <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
      {renderDots()}

      {/* NAV */}
      <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
        <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
      </div>
    </>
  );

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      <Glide
        slideLayout={renderSliderGallery}
        id="nc-GallerySlider-COMPONENT-1"
        images={galleryImgs || []}
        controls={controls}
      />
    </div>
  );
};

export default GallerySlider;

import React, { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonCircleSecondary from "shared/Button/ButtonCircleSecondary";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab?: (item: string) => void;
  onClickRefresh: () => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "",
  onClickTab,
  onClickRefresh,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  function handleLocationsRefresh() {
    onClickRefresh();
  }

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between">
        {/* DO YOU LIKE THIS */}
        {/* <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item}
              onClick={() => handleClickTab(item)}
            >
              {item}
            </NavItem>
          ))}
        </Nav> */}
        <span className="hidden sm:block flex-shrink-0">
          <ButtonSecondary href="/locations" className="!leading-none">
            <span>View all</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </ButtonSecondary>
        </span>
        <span>
          <ButtonCircleSecondary
            onClick={handleLocationsRefresh}
            // className="!leading-none w-0"
            className=""
            size=" w-12 h-12 "
          >
            {/* <span>Show Next</span> */}
            <i className="la la-refresh text-xl"></i>
          </ButtonCircleSecondary>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;

import { Popover, Transition } from "@headlessui/react";
import { FC, Fragment } from "react";
import { NavLink } from "react-router-dom";
import Avatar, { AvatarProps } from "shared/Avatar/Avatar";

export interface DropdownDropType {
  name: string;
  icon?: any;
  href?: string;
  onClick?: (data: any) => void;
}

export interface DropdownProps {
  data: DropdownDropType[];
  onClick?: (data: any) => void;
  trigger: any;
}

export const Dropdown: FC<DropdownProps> = ({ onClick, data, trigger }) => {
  return (
    <div className="Dropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              {trigger}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="sub-menu nc-will-change-transform absolute transform z-50 w-56 pt-3 -right-10 sm:right-0 sm:px-0"
                // className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0"
              >
                <ul className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                  {data.map((item, index) => {
                    return (
                      <li className="px-2">
                        {item.href ? (
                          <NavLink
                            end
                            rel="noopener noreferrer"
                            className={({ isActive }) =>
                              `flex items-center font-normal text-neutral-600 dark:text-neutral-300 py-2 px-4 rounded-md hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 ${
                                isActive
                                  ? "!font-medium  dark:!text-neutral-100"
                                  : ""
                              }`
                            }
                            to={item.href || ""}
                          >
                            {item.icon && (
                              <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                                <item.icon
                                  aria-hidden="true"
                                  className="w-6 h-6"
                                />
                              </div>
                            )}
                            <div className={item.icon ? "ml-4" : ""}>
                              <p className="text-sm font-medium ">
                                {item.name}
                              </p>
                            </div>
                          </NavLink>
                        ) : (
                          <div
                            onClick={onClick || item.onClick}
                            key={index}
                            className="flex items-center cursor-pointer font-normal text-neutral-600 dark:text-neutral-300 py-2 px-4 rounded-md hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"
                          >
                            {item.icon && (
                              <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                                <item.icon
                                  aria-hidden="true"
                                  className="w-6 h-6"
                                />
                              </div>
                            )}
                            <div className={item.icon ? "ml-4" : ""}>
                              <p className="text-sm font-medium ">
                                {item.name}
                              </p>
                            </div>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

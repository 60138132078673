import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import Input from "shared/Input/Input";
import InputWithButton from "components/InputWithButton/InputWithButton";

export interface HelpHeroProps {
  className?: string;
}

const HelpHero: FC<HelpHeroProps> = ({ className = "" }) => {
  return (
    <div className={`nc-HelpHero relative ${className}`} data-nc-id="HelpHero">
      <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
        {/* <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
          Work in new places
        </span> */}
        <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
          Help Center
        </h2>
        <div className="w-11/12 sm:w-3/5">
          <InputWithButton />
        </div>
      </div>
      <div className="relative aspect-w-1 aspect-h-[0.5] sm:aspect-w-4 sm:aspect-h-1 lg:aspect-w-16 lg:aspect-h-4">
        <img
          className="absolute inset-0 object-cover rounded-xl "
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default HelpHero;

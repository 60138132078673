import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LocationProps } from "pages/Locations/types";
import { StateProps } from "redux/types";
import request, { renderQueries } from "../../../utils/request";

const initialState: StateProps = {
  data: {},
  status: "idle",
  error: null,
};

export const fetchLocation = createAsyncThunk(
  "location/fetchLocation",
  async ({ id, ...obj }: any, { rejectWithValue }) => {
    try {
      const queries = renderQueries(obj);
      const res = await request(`/locations/${id}${queries}`);
      return res.data;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);

export const addNewLocation = createAsyncThunk(
  "location/addNewLocation",
  async ({ data }: any) => {
    const res = await request(`/locations`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updateLocation = createAsyncThunk(
  "location/updateLocation",
  async ({ data, id, ...obj }: any, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/locations/${id}${queries}`, {
      method: "put",
      data,
    });
    return res.data;
  }
);

export const removeFileFromLocation = createAsyncThunk(
  "location/removeFileFromLocation",
  async ({ data, id, ...obj }: any, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/locations/${id}/remove/image${queries}`, {
      method: "put",
      data,
    });

    return res.data;
  }
);

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    resetLocation() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLocation.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        state.data = {};
        state.status = "succeeded";
        state.data = action.payload.data;
      })
      .addCase(fetchLocation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewLocation.fulfilled, (state, action) => {
        state.data = action.payload.data;
      })
      .addCase(removeFileFromLocation.fulfilled, (state, action) => {
        if (action.payload.type === "coverImage") {
          state.data.coverImage = null;
        } else {
          const index = state.data.images.findIndex(
            (item: any) => item._id === action.payload._id
          );
          if (index > -1) {
            state.data.images.splice(index, 1);
          }
        }
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.data = action.payload.data;
      });
  },
});

export const { resetLocation } = locationSlice.actions;

export default locationSlice.reducer;

import useForm from "hooks/useForm";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Modal from "shared/Modal/Modal";
import request from "utils/request";
import FeedbackForm from "./FeedbackForm";

export interface FeedbackModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const FeedbackModal: FC<FeedbackModalProps> = ({ visible, setVisible }) => {
  const { form, updateForm, clearForm } = useForm();

  async function handleSubmit() {
    const res = await request("/feedback", {
      method: "post",
      data: form,
    });

    if (res.data) {
      toast(res.data.message, {
        type: res.data.status,
      });

      if (res.data.status === "success") {
        handleCancel();
      }
    }
  }

  function handleCancel() {
    setVisible(false);
    clearForm();
  }

  const modalFooter = (
    <div className="space-x-2">
      <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
      <ButtonPrimary onClick={handleSubmit}>Submit</ButtonPrimary>
    </div>
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      title="Submit feedback"
      footer={modalFooter}
      size="md"
      footerPosition="right"
    >
      <FeedbackForm form={form} updateForm={updateForm} />
    </Modal>
  );
};

export default FeedbackModal;

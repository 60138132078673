import React, { FC, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import Select, { OptionsProps, SelectProps } from "shared/Select/Select";
import MultiSelect from "shared/MultiSelect/MultiSelect";
import Input from "shared/Input/Input";

export interface NcInputSelectProps {
  className?: string;
  id: string;
  value: any;
  min?: number;
  max?: number;
  label?: string;
  desc?: string;
  onChange: (data: any) => void;
  options: OptionsProps[];
  multiSelect?: boolean;
  type?: string;
  placeholder?: string;
  defaultValue?: any;
}

const NcInputSelect: FC<NcInputSelectProps> = ({
  className = "w-full",
  min = 0,
  max,
  value,
  onChange,
  label,
  desc,
  id,
  options,
  multiSelect,
  type = "",
  placeholder = "",
  defaultValue,
}) => {
  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-medium text-neutral-800 dark:text-neutral-200">
          {label}
        </span>
        {desc && (
          <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
            {desc}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-NcInputSelect flex flex-col md:flex-row md:items-center md:justify-between md:space-x-5 ${className}`}
      data-nc-id="NcInputSelect"
    >
      {label && renderLabel()}

      <div
        className={`nc-NcInputSelect flex items-center justify-between w-full md:w-3/5`}
      >
        {type === "text" ? (
          <Input
            id={id}
            onChange={onChange}
            className="w-full"
            placeholder={placeholder}
            value={value}
          />
        ) : !multiSelect ? (
          <Select
            id={id}
            onChange={(e) => onChange({ id: e.id, value: e })}
            value={value}
            options={options}
            className="w-full"
          />
        ) : (
          <MultiSelect
            id={id}
            onChange={onChange}
            value={value}
            options={options}
            className="w-full"
            defaultValue={defaultValue}
          />
        )}
      </div>
    </div>
  );
};

export default NcInputSelect;

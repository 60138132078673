import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StateProps } from "redux/types";
import request, { renderQueries } from "../../../utils/request";
import _ from "lodash";

const initialState: StateProps = {
  data: {},
  status: "idle",
  error: null,
};

export const fetchReviews = createAsyncThunk(
  "reviews/fetchReviews",
  async (obj: any) => {
    const queries = renderQueries(obj);
    const res = await request(`/reviews${queries}`);
    return res.data;
  }
);

export const deleteReviews = createAsyncThunk(
  "reviews/deleteReviews",
  async ({ data }: any) => {
    const res = await request(`/reviews`, {
      method: "delete",
      data,
    });

    return res.data;
  }
);

export const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    resetReviews() {
      return initialState;
    },
    reviewAdded(state, action) {
      // state.data.reviews?.unshift(action.payload);
      state.data = {
        ...state.data,
        reviews: [action.payload, ...state.data.reviews],
      };
    },
    reviewDeleted(state, action) {
      const newData = state.data.reviews.filter(
        (x: any) => x._id !== action.payload
      );

      state.data = { ...state.data, reviews: newData };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReviews.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchReviews.fulfilled, (state, action) => {
        state.data = {};
        state.status = "succeeded";
        state.data = action.payload.data;
      })
      .addCase(fetchReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteReviews.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteReviews.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { data } = action.payload;
        const newData = state.data.filter((x: any) => !data.includes(x._id));
        state.data = newData;
      })
      .addCase(deleteReviews.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetReviews, reviewAdded, reviewDeleted } =
  reviewsSlice.actions;

export default reviewsSlice.reducer;

import classNames from "classnames";
import { FC, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import { MediaType } from "types";
import ModalPhotos from "./ModalPhotos";

const PHOTOS: string[] = [
  "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

export interface ImagesHeaderProps {
  images: string[] | MediaType[];
  coverImage: string | MediaType;
}

const ImagesHeader: FC<ImagesHeaderProps> = ({ coverImage, ...props }): any => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const cover = coverImage
    ? typeof coverImage === "string"
      ? coverImage
      : coverImage?.url || coverImage?.uri
    : "";

  const imgs =
    props.images?.map((i) => (typeof i === "string" ? i : i?.url || i?.uri)) ||
    [];

  const photos = [cover, ...imgs].filter((i: any) => i);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const onlyCoverImg = coverImage && imgs.length === 0;
  const coverImgWrapperClasses = classNames(
    "col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer",
    { "h-96": onlyCoverImg }
  );

  const gridWrapClasses = classNames("relative z-0", {
    "grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2": !onlyCoverImg,
    "w-4/6": onlyCoverImg,
  });

  const headerClasses = classNames(
    "container 2xl:px-14 rounded-md sm:rounded-xl",
    {
      "flex justify-center": onlyCoverImg,
    }
  );
  return (
    photos.length > 0 && (
      <>
        <header className={headerClasses}>
          <div className={gridWrapClasses}>
            {photos.length > 0 && (
              <>
                <div
                  className={coverImgWrapperClasses}
                  onClick={() => handleOpenModal(0)}
                >
                  <NcImage
                    containerClassName="absolute inset-0"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                    src={cover}
                  />
                  <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                </div>
                {/* MAIN PHOTO AND 2 TOP ARE CUSTOMER UPLOADED, REST ARE FROM REVIEWS */}
                {imgs.length > 0 &&
                  imgs
                    .filter((_, i, self) => {
                      const COUNT = self.length;
                      if (COUNT <= 2) {
                        return i < 1;
                      }
                      return i < 4;
                    })
                    .map((item, index, self) => {
                      const COUNT = self.length;
                      const imgCover = self[0];
                      const wrapper = classNames(
                        "relative rounded-md sm:rounded-xl overflow-hidden",
                        {
                          "hidden sm:block": index >= 3,
                          "col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer":
                            COUNT < 3,
                        }
                      );
                      const containerClassName = classNames(
                        "aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      );
                      const imgClass = classNames(
                        "object-cover w-full h-full rounded-md sm:rounded-xl"
                      );

                      return (
                        <div key={index} className={wrapper}>
                          <NcImage
                            containerClassName={containerClassName}
                            className={imgClass}
                            src={item || ""}
                          />

                          {/* OVERLAY */}
                          <div
                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                            onClick={() => handleOpenModal(index + 1)}
                          />
                        </div>
                      );
                    })}
              </>
            )}
            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={photos}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-LocationDetail-modalPhotos"
        />
      </>
    )
  );
};

export default ImagesHeader;

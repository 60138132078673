import { StayDataType, TaxonomyType } from "data/types";
import SectionGridLocations from "./SectionGridLocations";
import LocationCardVertical from "pages/Home/LocationCardVertical";
import SearchHeader from "./SearchHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocations,
  resetLocations,
} from "redux/reducers/locations/locationsSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SignUpModal from "components/SignUpModal/SignUpModal";
import { PaginateType } from "types";
import { useAuth } from "hooks/useAuth";
import { DEMO_STAY_LISTINGS } from "data/listings";

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

const limit = 20;

function Locations() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queries, setQueries] = useSearchParams();

  const searchQuery = queries.get("search");
  const experienceQuery = queries.get("experience");
  const seasonQuery = queries.get("season");
  const featureQuery = queries.get("feature");
  const regionQuery = queries.get("region");

  const [searchValue, setSearchValue] = useState(searchQuery || "");
  const [experience, setExperience] = useState(experienceQuery || "");
  const [season, setSeason] = useState(seasonQuery || "");
  const [feature, setFeature] = useState(featureQuery || "");
  const [region, setRegion] = useState(regionQuery || "");
  const [locationData, setLocationData] = useState<any[]>([]);
  const [pagination, setPagination] = useState<PaginateType | null>();
  const [visible, setVisible] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const locations = useSelector((state: any) => state.locations);

  useEffect(() => {
    if (locations.status === "idle") {
      getLocations({
        page: 1,
        searchValue,
        experience,
        season,
        feature,
        region,
      });
    }
  }, [locations.status]);

  useEffect(() => {
    return () => dispatch(resetLocations());
  }, []);

  const locationsNull = pagination?.count === 0;

  function removeParam(key: string) {
    if (queries.has(key)) {
      queries.delete(key);
      setQueries(queries);
    }
  }

  function handleSearchChange(e: any) {
    if ("searchValue" in e) {
      setSearchValue(e.searchValue);
    }

    if ("experience" in e) {
      setExperience(e.experience);
    }
    if ("feature" in e) {
      setFeature(e.feature);
    }
    if ("season" in e) {
      setSeason(e.season);
    }
    if ("region" in e) {
      setRegion(e.region);
    }
  }

  function getQueries(obj: any) {
    const queryObj: any = { limit, active: true, status: "published", ...obj };

    if (obj.page) {
      queryObj.page = obj.page;
    }
    if (obj.experience) {
      queryObj.experience = obj.experience;
    }
    if (obj.season) {
      queryObj.season = obj.season;
    }
    if (obj.feature) {
      queryObj.feature = obj.feature;
    }
    if (obj.region) {
      queryObj.region = obj.region;
    }
    if (obj.searchValue) {
      queryObj.searchValue = obj.searchValue;
    }

    return queryObj;
  }

  async function handleSearchSubmit(e: any) {
    const queries: any = await getQueries({ ...e });
    const res = await dispatch(fetchLocations(queries)).unwrap();

    if (res.status === "success") {
      setLocationData(res.data.locations);
      setPagination(res.data);

      removeParam("experience");
      removeParam("season");
      removeParam("feature");
      removeParam("region");
      removeParam("search");
    }
  }

  async function getLocations(e: any) {
    const queries: any = await getQueries({ ...e });
    const res = await dispatch(fetchLocations(queries)).unwrap();

    setLocationData([...locationData, ...res.data.locations]);
    setPagination(res.data);
  }

  function resetSearch() {
    removeParam("experience");
    removeParam("search");
    removeParam("feature");
    removeParam("season");
    removeParam("region");
    setPagination(null);
    setExperience("");
    setRegion("");
    setFeature("");
    setSeason("");
    setSearchValue("");
    getLocations({ page: 1 });
  }

  function handleCreateLocationFromSearch() {
    if (!user.active) return setVisible(true);
    navigate("/locations/create?name=" + searchValue);
  }

  const emptyData = locationsNull && (
    <>
      <div
        className={`border rounded-3xl px-4 max-w-3xl mx-auto sm:py-10 space-y-8`}
      >
        <div>
          <h2 className="text-2xl font-semibold">No Locations Found 🎉</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            We were not able to find any locations with that name
            {searchValue && showError
              ? ", however, we are always looking for contributors to create new locations, would you like to create this location?"
              : ""}
          </span>
        </div>
        {searchValue && showError && (
          <>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            {/* FORM */}
            <div>
              <h3 className="text-lg font-semibold">Create this location?</h3>
              <div className="max-w-xs">
                <LocationCardVertical
                  className="mt-8"
                  onClick={() => console.log("NAVIGATE")}
                  data={{
                    title: searchValue,
                    href: "",
                    stars: 0,
                    reviewCount: 0,
                    address: "",
                    images: DEMO_DATA[0].galleryImgs,
                    author: "",
                    coverImage: DEMO_DATA[0].galleryImgs[5],
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="flex items-center space-x-5 mt-8">
          <ButtonSecondary onClick={resetSearch}>Reset Search</ButtonSecondary>
          {searchValue && showError && (
            <ButtonPrimary onClick={handleCreateLocationFromSearch}>
              Create Location
            </ButtonPrimary>
          )}
        </div>
      </div>
      <SignUpModal visible={visible} setVisible={setVisible} />
    </>
  );

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      <div className="container relative space-y-5 mb-5">
        <SearchHeader
          searchValue={searchValue}
          experience={capitalizeFirstLetter(experience) || ""}
          season={capitalizeFirstLetter(season) || ""}
          feature={capitalizeFirstLetter(feature) || ""}
          region={capitalizeFirstLetter(region) || ""}
          onChange={handleSearchChange}
          onSubmit={handleSearchSubmit}
        />
        <SectionGridLocations
          getData={getLocations}
          data={{ ...pagination, locations: locationData }}
          emptyData={emptyData}
        />
        {/* <SectionSliderNewCategories
          categories={DEMO_CATS_2}
          categoryCardType="card4"
          itemPerRow={4}
          heading="Suggestions"
          subHeading="Popular places to work"
          uniqueClassName="PageHome2_s1"
        /> */}
      </div>
    </div>
  );
}

export default Locations;

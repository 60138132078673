import React, { FC, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import ButtonThird from "shared/Button/ButtonThird";
import Button from "shared/Button/Button";
import LoginSignUpButtons from "components/LoginSignUpButtons";
import { useAuth } from "hooks/useAuth";
import Avatar from "shared/Avatar/Avatar";
import { AvatarDropdown } from "../AvatarDropdown/AvatarDropdown";
import {
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
  ChatBubbleOvalLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import FeedbackModal from "pages/Feedback/FeedbackModal";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const { user, logout } = useAuth();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const data = [
    { name: "Profile", href: "/account", icon: UserCircleIcon },
    {
      name: "Feedback",
      onClick: () => setFeedbackModalOpen(true),
      icon: ChatBubbleOvalLeftEllipsisIcon,
    },
    {
      name: "Logout",
      onClick: () => logout(),
      icon: ArrowRightOnRectangleIcon,
    },
  ];

  return (
    <>
      <div className={`nc-MainNav1 relative z-10 ${className}`}>
        <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
          <div className="md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
            <Logo className="w-36" />
            <div className="invisible sm:visible">
              <Navigation />
            </div>
          </div>

          {/* <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div> */}

          <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
            <div className="hidden lg:flex items-center space-x-0.5">
              <SwitchDarkMode />
              {/* <SearchDropdown /> */}
              <div className="px-1" />
              {user.email ? (
                <AvatarDropdown
                  imgUrl={
                    typeof user.avatar === "string"
                      ? user.avatar
                      : user.avatar?.url
                  }
                  options={data}
                />
              ) : (
                <LoginSignUpButtons />
              )}
            </div>
            <div className="flex lg:hidden items-center">
              <SwitchDarkMode />
              <div className="px-0.5" />
              <MenuBar />
            </div>
          </div>
        </div>
      </div>
      <FeedbackModal
        visible={feedbackModalOpen}
        setVisible={setFeedbackModalOpen}
      />
    </>
  );
};

export default MainNav1;

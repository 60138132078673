import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Tags from "./Tags";

export interface SearchHeaderProps {
  season?: string;
  feature?: string;
  region?: string;
  experience?: string;
  searchValue?: string;
  onChange: (event: any) => void;
  onSubmit: (event?: any) => void;
}

const SearchHeader: FC<SearchHeaderProps> = ({
  experience,
  season,
  feature,
  region,
  searchValue,
  onChange,
  onSubmit,
}) => {
  function handleTagChange(e: any) {
    // onSubmit && onSubmit({ [e.id]: e.value.name });
    onChange && onChange({ [e.id]: e.value.name });
  }

  return (
    <div className="listingSection__wrap mt-8">
      <div className="relative">
        <Input
          id="searchValue"
          fontClass=""
          sizeClass="h-16 px-4 py-3"
          rounded="rounded-3xl"
          placeholder="Search loctions..."
          onChange={({ target }) => onChange({ [target.id]: target.value })}
          value={searchValue}
        />
        <ButtonPrimary
          onClick={() => onSubmit({ searchValue, experience })}
          className="!absolute right-2 top-1/2 transform -translate-y-1/2"
        >
          Search
        </ButtonPrimary>
      </div>
      <Tags
        onChange={handleTagChange}
        experience={experience}
        season={season}
        feature={feature}
        region={region}
      />
    </div>
  );
};

export default SearchHeader;

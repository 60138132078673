import { FC, useState } from "react";

export interface ShowMoreProps {
  className?: string;
  children: string;
  length?: number;
}

const ShowMore: FC<ShowMoreProps> = ({
  className = "",
  children,
  length = 200,
}) => {
  const [showMore, setShowMore] = useState(false);

  const paragraph = children;
  const canExpand = paragraph.length > length;
  const body =
    canExpand && !showMore
      ? paragraph.substring(0, length).trim() + "..."
      : paragraph;

  return (
    <div className={className}>
      {body}
      {canExpand && (
        <span
          onClick={() => setShowMore(!showMore)}
          className="text-sm hover:underline cursor-pointer ml-2 text-primary-600"
        >
          {showMore ? "Show less" : "Show more"}
        </span>
      )}
    </div>
  );
};

export default ShowMore;

import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionHero3 from "components/SectionHero/SectionHero3";
import LocationsExperience from "./LocationsExperience";
import { useNavigate } from "react-router-dom";
import { experiences, ExperienceType } from "constants/experiences";
import SectionGridFeaturedLocations from "./SectionGridFeaturedLocations";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchLocations } from "redux/reducers/locations/locationsSlice";

function PageHome3() {
  const navigate = useNavigate();

  function handleExerperienceClick(e: ExperienceType) {
    navigate("/locations?experience=" + e.id);
  }

  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container px-1 sm:px-4 mb-24 ">
        <SectionHero3 className="" />
      </div>

      <div className="container relative space-y-24 mb-24 ">
        {/* SECTION 1 */}
        <LocationsExperience
          onClick={handleExerperienceClick}
          data={experiences}
        />

        {/* SHOULD WE USE THIS? */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection className="bg-primary-100 bg-opacity-10 dark:bg-black dark:bg-opacity-20" />
          <SectionGridFeaturedLocations />
          {/* <SectionGridAuthorBox boxCard="box2" /> */}
        </div>
      </div>
    </div>
  );
}

export default PageHome3;

import { StarIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import { formatDate } from "utils";

interface CommentListingDataType {
  name: string;
  avatar?: string;
  dateCreated: string;
  body: string;
  stars: number;
  author: {};
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType;
  hasListingTitle?: boolean;
}

const DEMO_DATA: CommentListingDataType = {
  name: "Cody Fisher",
  dateCreated: "May 20, 2021",
  body: "",
  stars: 5,
  author: {},
};

const CommentListing: FC<CommentListingProps> = ({
  className = "",
  data = DEMO_DATA,
  hasListingTitle,
}) => {
  return (
    <div
      className={`nc-CommentListing flex space-x-4 ${className}`}
      data-nc-id="CommentListing"
    >
      <div className="pt-0.5">
        <Avatar
          sizeClass="h-10 w-10 text-lg"
          radius="rounded-full"
          userName={data.name}
          imgUrl={data.avatar}
        />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between space-x-3">
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              <span>{data.name}</span>
              {hasListingTitle && (
                <>
                  <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                    {` review in `}
                  </span>
                  <a href="/">The Lounge & Bar</a>
                </>
              )}
            </div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
              {formatDate(data.dateCreated)}
            </span>
          </div>
          <div className="flex text-yellow-500">
            {Array.from(Array(data.stars).keys()).map((item) => {
              return <StarIcon className="w-4 h-4" />;
            })}
          </div>
        </div>
        <span className="mt-3 flex flex-col text-neutral-600 dark:text-neutral-300">
          {data.body}
          <span className="text-sm hover:underline cursor-pointer">
            Show more
          </span>
        </span>
      </div>
    </div>
  );
};

export default CommentListing;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";

import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome from "containers/PageHome/PageHome";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";

// Will stay
import SiteHeader from "containers/SiteHeader";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import Home from "pages/Home";
import Login from "pages/Account/Login/index";
import Signup from "pages/Account/Signup";
import ChangePassword from "pages/Account/ChangePassword";
import Faqs from "pages/FAQs";
import Contact from "pages/Contact";
import Help from "pages/Help";
import Locations from "pages/Locations/All";
import { AuthProvider } from "hooks/useAuth";
import { ToastContainer } from "react-toastify";
import CreateLocation from "pages/Locations/Create";
import ForgotPassword from "pages/Account/ForgotPassword";
import LocationDetail from "pages/Locations/Show";
import { RootLayoutProvider } from "layouts/RootContext";
import Feedback from "pages/Feedback";

export const pages: Page[] = [
  // Main App
  { path: "/", exact: true, component: Home },
  { path: "/#", exact: true, component: Home },
  // Locations
  { path: "/locations", exact: true, component: Locations },
  { path: "/locations/:id", exact: true, component: LocationDetail },
  { path: "/locations/:id/edit", exact: true, component: CreateLocation },
  { path: "/locations/create", exact: true, component: CreateLocation },
  // Help
  { path: "/help", component: Help },
  { path: "/help/faqs", component: Faqs },
  { path: "/contact", component: Contact },
  // { path: "/feedback", component: Feedback },
  { path: "/about", component: PageAbout },
  // Account
  { path: "/signup", component: Signup },
  { path: "/login", component: Login },
  { path: "/forgot/password", component: ForgotPassword },
  { path: "/change/password", component: ChangePassword },
  // End Main App

  // TEMPLATES
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  // { path: "/home-3", component: PageHome3 },
  //
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  //
  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  // { path: "/profile", component: Account },
  { path: "/account", component: AccountPage },
  // { path: "/account-reviews", component: AccountReviews },
  // { path: "/account-locations", component: AccountLocations },
  { path: "/account-password", component: AccountPass },
  // { path: "/account-savelists", component: AccountSavelists },
  // { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/subscription", component: PageSubcription },
  //
];

const MyRoutes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <BrowserRouter>
      <AuthProvider>
        <RootLayoutProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ScrollToTop />
          <SiteHeader />

          <Routes>
            {pages.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />;
            })}
            <Route path="*" element={<Page404 />} />
          </Routes>

          {WIN_WIDTH < 768 && <FooterNav />}
          <Footer />
        </RootLayoutProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default MyRoutes;

import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Avatar, { AvatarProps } from "shared/Avatar/Avatar";

export interface AvatarDropType {
  name: string;
  icon?: any;
  href?: string;
  onClick?: (data: any) => void;
}

export interface AvatarDropdownProps extends AvatarProps {
  options: AvatarDropType[];
  onClick?: (data: any) => void;
}

export const AvatarDropdown: FC<AvatarDropdownProps> = ({
  imgUrl,
  userName,
  onClick,
  options,
}) => {
  let timeout: any; // NodeJS.Timeout
  const timeoutDuration = 0;

  const buttonRef = useRef<HTMLButtonElement>(null); // useRef<HTMLButtonElement>(null)
  const [openState, setOpenState] = useState<boolean>(false);

  const toggleMenu = (open: boolean) => {
    // log the current open state in React (toggle open state)
    setOpenState((openState) => !openState);
    // toggle the menu by clicking on buttonRef
    buttonRef?.current?.click(); // eslint-disable-line
  };

  // Open the menu after a delay of timeoutDuration
  const onHover = (open: boolean, action: string) => {
    // if the modal is currently closed, we need to open it
    // OR
    // if the modal is currently open, we need to close it
    if (
      (!open && !openState && action === "onMouseEnter") ||
      (open && openState && action === "onMouseLeave")
    ) {
      // clear the old timeout, if any
      clearTimeout(timeout);
      // open the modal after a timeout
      timeout = setTimeout(() => toggleMenu(open), timeoutDuration);
    }
    // else: don't click! 😁
  };

  const handleClick = (open: boolean) => {
    setOpenState(!open); // toggle open state in React state
    clearTimeout(timeout); // stop the hover timer if it's running
  };

  const LINK_STYLES = classNames(
    "py-5 px-1 w-48",
    "text-base text-gray-900 uppercase font-bold",
    "transition duration-500 ease-in-out",
    "bg-gray-100 hover:text-blue-700 hover:bg-blue-100"
  );

  const handleClickOutside = (event: any) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <div
              onMouseEnter={() => onHover(open, "onMouseEnter")}
              onMouseLeave={() => onHover(open, "onMouseLeave")}
            >
              <Popover.Button
                ref={buttonRef}
                onClick={() => handleClick(open)}
                className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <Avatar
                  imgUrl={imgUrl}
                  userName={userName}
                  sizeClass="w-12 h-12 "
                />
              </Popover.Button>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="sub-menu nc-will-change-transform absolute transform z-10 w-56 pt-3 -right-0 sm:right-0 sm:px-0"
                >
                  <ul className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                    {options.map((item, index) => {
                      return (
                        <li key={item.name} onClick={close} className="px-2">
                          {item.href ? (
                            <NavLink
                              end
                              rel="noopener noreferrer"
                              className={({ isActive }) =>
                                `flex items-center font-normal text-neutral-600 dark:text-neutral-300 py-2 px-4 rounded-md hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 ${
                                  isActive
                                    ? "!font-medium  dark:!text-neutral-100"
                                    : ""
                                }`
                              }
                              to={item.href || ""}
                            >
                              {item.icon && (
                                <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                                  <item.icon
                                    aria-hidden="true"
                                    className="w-6 h-6"
                                  />
                                </div>
                              )}
                              <div className="ml-4">
                                <p className="text-sm font-medium ">
                                  {item.name}
                                </p>
                              </div>
                            </NavLink>
                          ) : (
                            <div
                              key={item.name}
                              onClick={(e) => {
                                onClick && onClick(e);
                                item.onClick && item.onClick(e);
                                close();
                              }}
                              className="flex items-center cursor-pointer font-normal text-neutral-600 dark:text-neutral-300 py-2 px-4 rounded-md hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"
                            >
                              {item.icon && (
                                <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                                  <item.icon
                                    aria-hidden="true"
                                    className="w-6 h-6"
                                  />
                                </div>
                              )}
                              <div className="ml-4">
                                <p className="text-sm font-medium ">
                                  {item.name}
                                </p>
                              </div>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Popover.Panel>
              </Transition>
            </div>
          </>
        )}
      </Popover>
    </div>
  );
};

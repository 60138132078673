import { FC } from "react";
import Modal from "shared/Modal/Modal";

interface SignUpModalProps {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

const SignUpModal: FC<SignUpModalProps> = ({ visible, setVisible }) => {
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      content="Please activate your account"
    />
  );
};

export default SignUpModal;

export function isObject(obj: {}) {
  return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}

export function capitalizeFirstLetter(str: string | null) {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatDate(value: string, dbFormat?: boolean) {
  if (typeof value === "string" && !dbFormat) {
    const newDate = value.substring(0, 10).split("-");

    const year = newDate[0].length !== 4 ? newDate[2] : newDate[0];
    const month = newDate[0].length !== 4 ? newDate[0] : newDate[1];
    const day = newDate[0].length !== 4 ? newDate[1] : newDate[2];

    return [month, day, year].join("-");
  }

  const newDate = value ? new Date(value) : new Date();
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;
  const date = newDate.getDate();

  if (dbFormat) {
    return `${year}-${month}-${date}`;
  }

  return `${month}-${date}-${year}`;
}

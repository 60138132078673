import React, { FC, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Glide from "@glidejs/glide";
// import { getDirection } from "../../helpers/Utils";
import "@glidejs/glide/dist/css/glide.core.min.css";
import { MediaType } from "types";

interface GlideComponentProps {
  settings?: {
    type: string;
    startAt: number;
    perView: number;
    focusAt: number | string;
    gap: number;
    autoplay: boolean;
    hoverpause: boolean;
    keyboard: boolean;
    bound: boolean;
    swipeThreshold: number | boolean;
    dragThreshold: number | boolean;
    perTouch: number | boolean;
    touchRatio: number;
    touchAngle: number;
    animationDuration: number;
    rewind: boolean;
    rewindDuration: number;
    animationTimingFunc: string;
    direction: string;
    hideNav: boolean;
    peek: object;
    breakpoints: object;
    classes: object;
    throttle: number;
  };
  id: string;
  className?: string;
  slideLayout: (item: any, index: number) => any;
  images: MediaType[] | string[];
  controls: any;
  children?: any;
}

let resizeTimeOut: any = -1;
let mountTimeOut: any = -1;

const GlideComponent: FC<GlideComponentProps> = (props) => {
  const ref = useRef<any>(null);
  const [glideCarousel, setGlideCarousel] = useState<any>();
  const [carousel, setCarousel] = useState();

  useEffect(() => {
    return () => {
      destroyGlide();
      initGlide();
    };
  }, []);

  function initGlide() {
    if (ref.current) {
      const newGlide = new Glide(ref.current, {
        perView: 1,
        gap: 0,
        keyboard: false,
      });

      newGlide.mount();
      newGlide.on("resize", onResize);
      mountTimeOut = setTimeout(() => {
        var event = document.createEvent("HTMLEvents");
        event.initEvent("resize", false, false);
        window.dispatchEvent(event);
      }, 500);
      setGlideCarousel(newGlide);
    }
  }

  // componentWillUnmount() {
  //   this.destroyGlide();
  // }

  function destroyGlide() {
    clearTimeout(resizeTimeOut);
    clearTimeout(mountTimeOut);
    if (glideCarousel) {
      glideCarousel.destroy();
    }
  }

  function onResize() {
    clearTimeout(resizeTimeOut);
    resizeTimeOut = setTimeout(() => {
      glideCarousel.update();
      resizeTimeOut = -1;
    }, 500);
  }

  function renderDots() {
    let total = React.Children.count(props.children);
    let dots = [];
    for (let i = 0; i < total; i++) {
      dots.push(
        <button
          className="glide__bullet slider-dot"
          key={i}
          data-glide-dir={"=" + i}
        ></button>
      );
    }
    return dots;
  }

  return (
    <div>
      <div className="glide" ref={ref}>
        <div data-glide-el="track" className="glide__track">
          <div className="glide__slides">
            {props.children ||
              props.images.map((item, i) => (
                <li key={i} className="glide__slide">
                  {props.slideLayout(item, i)}
                </li>
              ))}
          </div>
        </div>
        {/* {!props.settings?.hideNav && (
          <div className="glide__arrows slider-nav" data-glide-el="controls">
            <button
              className="glide__arrow glide__arrow--left left-arrow btn btn-link"
              data-glide-dir="<"
            >
              <i className="simple-icon-arrow-left"></i>
            </button>

            <div
              className="glide__bullets slider-dot-container"
              data-glide-el="controls[nav]"
            >
              {renderDots()}
            </div>

            <button
              className="glide__arrow glide__arrow--right right-arrow btn btn-link"
              data-glide-dir=">"
            >
              <i className="simple-icon-arrow-right"></i>
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};
export default GlideComponent;

import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import { LocationDataProps } from "types";
import Badge from "shared/Badge/Badge";
import Carousel from "shared/Carousel/Carousel";

export interface LocationCardProps {
  className?: string;
  data: LocationDataProps;
  size?: "default" | "small";
  onClick?: (e: any) => void;
}

const LocationCard: FC<LocationCardProps> = ({
  size = "default",
  className = "",
  data,
  onClick,
}) => {
  const {
    images,
    staffSize,
    address,
    title,
    href,
    stars,
    reviewCount,
    id,
    experience,
    name,
  } = data;

  const renderSliderGallery = () => {
    return (
      images.length > 0 && (
        <div className="relative w-full">
          {/* <Carousel images={images} href={href} /> */}
          <GallerySlider
            uniqueID={`LocationCard_${id}`}
            ratioClass="aspect-w-4 aspect-h-3 "
            galleryImgs={images}
            href={href}
          />
          {/* V2 */}
          {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
          {/* V2 */}
          {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
        </div>
      )
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {/* PROPERTY TYPE */} {/* GUEST SIZE */}
            {/* {category || "Category"} · {"1,000"} guests */}
            {experience && <Badge name={experience} color="green" />}
            {/* ·{" "}{staffSize} employees */}
          </span>
          <div className="flex items-center space-x-2">
            {/* V3 */}
            {/* {experience && <Badge name={experience} color="green" />} */}
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title || name}</span>
            </h2>
          </div>
          {address && (
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              {size === "default" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
              <span className="">
                {/* CITY AND STATE */}
                {address && typeof address === "string"
                  ? address
                  : `${address.city}, ${address.state}`}
              </span>
            </div>
          )}
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          {/* <span className="text-base font-semibold">
            {price}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /night
              </span>
            )}
          </span> */}
          {<StartRating reviewCount={reviewCount} point={stars} />}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-LocationCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      // data-nc-id="LocationCard"
    >
      {renderSliderGallery()}
      {onClick ? (
        <div className="cursor-pointer" onClick={onClick}>
          {renderContent()}
        </div>
      ) : (
        <Link to={href}>{renderContent()}</Link>
      )}
    </div>
  );
};

export default LocationCard;

import React, { FC } from "react";
import Textarea from "shared/Textarea/Textarea";
import { LocationCreateProps } from ".";
import LocationCreateLayout from "./FormLayout";

export interface LocationDescProps extends LocationCreateProps {}

const LocationDesc: FC<LocationDescProps> = ({ form, updateForm }) => {
  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Describe your location</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Mention the best features of your location.
        </span>
      </div>

      <Textarea
        placeholder="Add a description of your location here..."
        rows={14}
        id="description"
        value={form.description}
        onChange={(e) => updateForm && updateForm(e.target)}
      />
    </>
  );
};

export default LocationDesc;

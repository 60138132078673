import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import convertNumbThousand from "utils/convertNumbThousand";
import { ExperienceType } from "constants/experiences";

export interface CardExperienceProps {
  className?: string;
  taxonomy: ExperienceType;
  onClick?: (obj: ExperienceType) => string | void;
}

const CardExperience: FC<CardExperienceProps> = ({
  className = "flex-1",
  taxonomy,
  onClick,
}) => {
  const { count, name, thumbnail } = taxonomy;

  const aspectRatio = (
    <div className="aspect-w-16 aspect-h-10 sm:aspect-h-12 xl:aspect-h-9 w-full h-0"></div>
  );

  const image = (
    <NcImage
      src={thumbnail}
      className="object-cover absolute inset-0 w-full h-full rounded-2xl group-hover:scale-105 transition-transform duration-300"
    />
  );

  const info = (
    <div className="absolute bottom-0 inset-x-0 p-4 sm:p-6 text-white">
      <span className="absolute inset-0 bg-gradient-to-t from-black/60"></span>
      <h2 className={`relative text-lg lg:text-xl font-semibold`}>{name}</h2>
      {/* <span className={`relative block mt-1.5 text-sm text-neutral-100`}>
        {convertNumbThousand(count)} locations
      </span> */}
    </div>
  );

  const wrapperClasses = `nc-CardExperience relative flex w-full group rounded-2xl z-0 overflow-hidden cursor-pointer ${className}`;
  const id = "CardExperience";

  function handleClick() {
    onClick && onClick(taxonomy);
  }

  return (
    <div className={wrapperClasses} data-nc-id={id} onClick={handleClick}>
      {aspectRatio}
      {image}
      {info}
    </div>
  );
};

export default CardExperience;

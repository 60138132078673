import { StarIcon } from "@heroicons/react/24/solid";
import { AuthorType, CommentListingDataType } from "types";
import React, { FC, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import { formatDate } from "utils";
import { TEMP_USER } from "constants/TEMP_DATA";
import moment from "moment";
import ModalPhotos from "./ModalPhotos";
import ShowMore from "components/ShowMore/ShowMore";
import { useAuth } from "hooks/useAuth";
import { useDispatch } from "react-redux";
import request from "utils/request";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { reviewDeleted } from "redux/reducers/reviews/reviewsSlice";
import Modal from "shared/Modal/Modal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface CommentListingProps {
  className?: string;
  data: CommentListingDataType;
  hasListingTitle?: boolean;
  onImageClick: (imgs: any) => void;
  handleDelete: (id: string) => void;
}

const DEMO_DATA: CommentListingDataType = {
  name: "Cody Fisher",
  dateCreated: "May 20, 2021",
  body: "",
  stars: 5,
  author: TEMP_USER,
};

const CommentListing: FC<CommentListingProps> = ({
  className = "",
  data, // = DEMO_DATA,
  hasListingTitle,
  onImageClick,
  handleDelete,
}) => {
  const { user } = useAuth();
  const [imgModelOpen, setImgModalOpen] = useState<boolean>(false);
  const [deleteModelOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const timeAgo = moment(data.dateCreated).fromNow();
  const author: AuthorType = data.author;

  function showReviewImages(index: number) {
    setOpenFocusIndex(index);
    setImgModalOpen(true);
  }

  function handleDeleteModal() {
    setDeleteModalOpen(true);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
  }

  const modalFooter = (
    <div className="space-x-2">
      <ButtonSecondary onClick={handleDeleteModalClose}>Cancel</ButtonSecondary>
      <ButtonPrimary onClick={() => data._id && handleDelete(data._id)}>
        Delete
      </ButtonPrimary>
    </div>
  );

  const canEdit = author?._id === user._id || user.authority?.includes("dmin");
  console.log(author, canEdit);
  return (
    <>
      <div
        className={`nc-CommentListing flex space-x-4 ${className}`}
        data-nc-id="CommentListing"
      >
        <div className="pt-0.5">
          <Avatar
            sizeClass="h-10 w-10 text-lg"
            radius="rounded-full"
            userName={data.name}
            imgUrl={data.avatar}
          />
        </div>
        <div className="flex-grow">
          <div className="flex justify-between space-x-3">
            <div className="flex flex-col">
              <div className="text-sm font-semibold">
                <span>
                  {author &&
                    (author.displayName ? author.displayName : "Anonymous")}
                </span>
                {hasListingTitle && (
                  <>
                    <span className="text-neutral-500 dark:text-neutral-400 font-normal">
                      {` review in `}
                    </span>
                    <a href="/">The Lounge & Bar</a>
                  </>
                )}
              </div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
                {timeAgo}
              </span>
            </div>
            <div className="flex text-yellow-500">
              {Array.from(Array(data.stars).keys()).map((item, i) => {
                return <StarIcon key={i} className="w-4 h-4" />;
              })}
            </div>
          </div>
          <span className="mt-3 flex flex-col text-neutral-600 dark:text-neutral-300">
            <ShowMore>{data.body}</ShowMore>
            {data.images && (
              <>
                {data.images.map((image: any, index: number) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="w-28 h-28 border relative rounded-xl"
                      >
                        <div
                          className="absolute inset-0 bg-cover bg-center z-0 rounded-xl"
                          style={{
                            backgroundImage: `url('${image.url}')`,
                          }}
                        ></div>
                        <div
                          onClick={() => showReviewImages(index)}
                          className="opacity-0 cursor-pointer hover:opacity-100 duration-300 rounded-xl absolute inset-0 z-10 flex justify-end text-6xl text-white font-semibold"
                        >
                          <div className="bg-black opacity-30 rounded-xl w-full h-full absolute" />
                        </div>
                      </div>
                    </>
                  );
                })}
                <ModalPhotos
                  imgs={data.images}
                  isOpen={imgModelOpen}
                  onClose={() => setImgModalOpen(false)}
                  initFocus={openFocusIndex}
                  uniqueClassName={`nc-Review-modalPhotos-${data._id}`}
                />
              </>
            )}
          </span>
          {canEdit && (
            <div className="mt-4 space-x-2">
              <span className="text-primary-600 cursor-pointer hover:underline">
                Edit
              </span>
              <span
                onClick={handleDeleteModal}
                className="text-red-600 cursor-pointer hover:underline"
              >
                Delete
              </span>
            </div>
          )}
        </div>
      </div>
      <Modal
        visible={deleteModelOpen}
        setVisible={setDeleteModalOpen}
        title="Delete review"
        content="Are you sure you want to delete this review?"
        footer={modalFooter}
      />
    </>
  );
};

export default CommentListing;

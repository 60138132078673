import classNames from "classnames";
import useScrollPosition from "hooks/useScrollPosition";
import { useLayout } from "layouts/RootContext";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import isInViewport from "utils/isInViewport";

let WIN_PREV_POSITION = window.pageYOffset;
let SHOW_SCROLL = 450;

export interface ScrollToTopProps {}

const ScrollToTop: React.FC<ScrollToTopProps> = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { isMobileNavShowing } = useLayout();
  const scrollPosition = useScrollPosition();

  const locationPathName = location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [locationPathName]);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;
    if (!containerRef.current) return;

    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }

      containerRef.current.classList.remove("invisible");
    } else {
      if (!isInViewport(containerRef.current)) {
        return;
      }
      containerRef.current.classList.add("invisible");
    }

    WIN_PREV_POSITION = currentScrollPos;
  };

  const buttonClasses = classNames(
    "z-50 fixed bottom-8 right-8 border-0",
    "w-12 h-12 rounded-full transition-all drop-shadow-md",
    "bg-primary-600 text-white text-3xl font-bold",
    { "bottom-16": isMobileNavShowing }
  );

  return scrollPosition > SHOW_SCROLL ? (
    <div ref={containerRef}>
      <button
        id="to-top-button"
        onClick={scrollToTop}
        className={buttonClasses}
      >
        &uarr;
      </button>
    </div>
  ) : null;
};

export default ScrollToTop;

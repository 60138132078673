import {
  EnvelopeIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import request, { renderQueries } from "utils/request";

const colors = {
  primary: "text-primary-700 border-primary-300 bg-primary-50",
};

export interface BannerProps {
  color?: string;
  content?: any;
  children?: any;
  icon?: React.ReactElement;
  title?: React.ReactElement | string;
}

const Banner: FC<BannerProps> = ({ color, icon, title, content, children }) => {
  const wrapperClass = classNames("p-4 mb-4 border rounded-lg", {
    [colors.primary]: color === "primary" || !color,
  });

  const bannerIcon = icon ? (
    icon
  ) : (
    <InformationCircleIcon className="w-5 h-5 mr-2" />
  );

  return (
    <div id="alert-additional-content-4" className={wrapperClass} role="alert">
      <div className="flex items-center">
        {bannerIcon}
        {title && <h3 className="text-lg font-medium">{title}</h3>}
      </div>
      <div className="my-2 text-sm">{content || children}</div>
    </div>
  );
};

export default Banner;

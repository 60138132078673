import React, { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { LocationCreateProps } from ".";
import FormItem from "./FormItem";

export interface LocationContactInfoProps extends LocationCreateProps {}

const LocationContactInfo: FC<LocationContactInfoProps> = ({
  form,
  updateForm,
}) => {
  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Contact Info</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          This is used for users to get ahold of your location, can be set to
          public or private
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* FORM */}
      <div className="space-y-8">
        {/* ITEM */}
        <FormItem label="Email">
          <Input
            id="email"
            value={form.email}
            placeholder="user@example.com"
            onChange={({ target }) => updateForm && updateForm(target)}
          />
        </FormItem>
        <FormItem label="Phone">
          <Input
            id="phone"
            value={form.phone}
            placeholder="555-555-5555"
            onChange={({ target }) => updateForm && updateForm(target)}
          />
        </FormItem>
        <FormItem label="Website">
          <Input
            id="website"
            value={form.website}
            placeholder="example.com"
            onChange={({ target }) => updateForm && updateForm(target)}
          />
        </FormItem>
      </div>
    </>
  );
};

export default LocationContactInfo;

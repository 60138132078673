import { FC, useState } from "react";
import imagePng from "images/travelhero2.png";
import InputWithButton from "components/InputWithButton/InputWithButton";
import { useNavigate } from "react-router-dom";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  function handleSearch() {
    navigate("locations?search=" + search);
  }

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
        <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
          Work in new places
        </span>
        <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
          New generation <br /> of working
        </h2>
        <div className="w-10/12 md:w-3/5">
          <InputWithButton
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onClick={handleSearch}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
        <img
          className="absolute inset-0 object-cover rounded-xl"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;

import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import useForm, { renderValidations } from "hooks/useForm";
import useQuery from "hooks/useQuery";
import LoginLayout from "../../../layouts/LoginLayout";
import ChangePasswordForm from "./Form";
import request, { renderQueries } from "utils/request";
import { toast } from "react-toastify";

export interface ChangePasswordProps {
  className?: string;
}

const ChangePassword: FC<ChangePasswordProps> = ({ className = "" }) => {
  const queries = useQuery();
  const token = queries.get("token");
  const navigate = useNavigate();

  const { form, updateForm } = useForm({
    validations: renderValidations([
      {
        id: "email",
        validations: { required: true, email: true },
      },
      {
        id: "password",
        required: true,
      },
    ]),
  });

  const footer = (
    <>
      New user?{` `}
      <Link className="text-primary-600" to="/signup">
        Create an account
      </Link>
    </>
  );

  async function handleSubmit() {
    const queries = { token };
    const res = await request(
      "/account/change/password" + renderQueries(queries),
      {
        method: "post",
        data: { password: form.password },
      }
    );

    if (res.data.status === "success") {
      toast(res.data.message, {
        type: res.data.status,
      });
      navigate("/login");
    } else {
      toast(res.data.message, {
        toastId: "passwordProfileChangeFailed",
        type: "error",
      });
    }
  }

  return (
    <div
      className={`nc-ChangePassword ${className}`}
      data-nc-id="ChangePassword"
    >
      <LoginLayout
        footer={footer}
        title="Change Password"
        onSubmit={handleSubmit}
      >
        <ChangePasswordForm form={form} updateForm={updateForm} />
      </LoginLayout>
    </div>
  );
};

export default ChangePassword;

import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useForm from "hooks/useForm";
import { AuthContextProps, useAuth } from "hooks/useAuth";
import { useDispatch } from "react-redux";
import { forgotPassword } from "redux/reducers/accountSlice";
import { toast } from "react-toastify";
import request from "utils/request";
import LoginLayout from "layouts/LoginLayout";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const ForgotPassword: FC<PageLoginProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { form, updateForm } = useForm();

  function handleChange(e: any) {
    const { id, value } = e.target;
    updateForm({ id, value });
  }

  async function handleSubmit() {
    const email = await request("/account/forgot/password", {
      method: "post",
      data: form,
    });

    toast(email.data.message, {
      type: email.data.status,
      toastId: "forgotPassword",
    });
    if (email.data.status === "success") {
      navigate("/login");
    }
  }

  return (
    <LoginLayout
      id="LoginPage"
      title="Login"
      footer={
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          New user? {` `}
          <Link className="text-primary-600" to="/signup">
            Create an account
          </Link>
        </span>
      }
      buttonTitle="Submit"
      onSubmit={handleSubmit}
    >
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Email address
        </span>
        <Input
          id="email"
          value={form.email}
          onChange={handleChange}
          type="email"
          placeholder="example@example.com"
          className="mt-1"
        />
      </label>
    </LoginLayout>
    // <div
    //   className={`nc-ForgotPassword ${className}`}
    //   data-nc-id="ForgotPassword"
    // >
    //   <Helmet>
    //     <title>Forgot Password || Upseasonal</title>
    //   </Helmet>
    //   <div className="container mb-24 lg:mb-32">
    //     <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
    //       Forgot Password
    //     </h2>
    //     <div className="max-w-md mx-auto space-y-6">
    //       {/* FORM */}
    //       <div className="grid grid-cols-1 gap-6">
    //         <label className="block">
    //           <span className="text-neutral-800 dark:text-neutral-200">
    //             Email address
    //           </span>
    //           <Input
    //             id="email"
    //             value={form.email}
    //             onChange={handleChange}
    //             type="email"
    //             placeholder="example@example.com"
    //             className="mt-1"
    //           />
    //         </label>
    //         <ButtonPrimary onClick={handleSubmit}>Send Email</ButtonPrimary>
    //       </div>
    //       <span className="block text-center text-neutral-700 dark:text-neutral-300">
    //         Already have an account? {` `}
    //         <Link to="/login">Sign in</Link>
    //       </span>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ForgotPassword;

import LocationCreateLayout from "./FormLayout";
import LocationInfo from "./Info";
import LocationAddress from "./Address";
import LocationDetails from "./Details";
import { useLocation } from "react-router-dom";
import { UpdateFormType, UseFormReturnProps } from "hooks/types";
import LocationReview from "./Review";
import LocationPhotos from "./Photos";
import LocationDesc from "./Desc";
import LocationHousing from "./Housing";
import { useAuth } from "hooks/useAuth";
import LocationTags from "./Tags";
import LocationContactInfo from "./ContactInfo";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetLocation } from "redux/reducers/locations/locationSlice";

export interface LocationCreateProps {
  onChange?: (data: any) => void;
  form?: any;
  isValid?: boolean;
  errors?: {};
  updateForm?: UpdateFormType;
  setFormInit?: (obj: any, options?: any) => void;
  clearForm?: (id?: string) => void;
  toPage?: (id?: number) => void;
  onSubmit?: (e?: any) => void;
}

export function getFullAddress(address: {
  street: string;
  city: string;
  state: string;
  zipcode: string;
}) {
  if (!address.street || !address.city || !address.state || !address.zipcode) {
    return null;
  }

  const fullAddress = `${address.street}, ${address.city}, ${address.state} ${address.zipcode}`;
  return fullAddress;
}

const CreateLocation = () => {
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(resetLocation());
  }, []);

  function renderLayout() {
    const path = location.search.substring(6);
    switch (path) {
      case "review":
        return <LocationReview />;
      case "contact":
        return <LocationContactInfo />;
      case "tags":
        return <LocationTags />;
      case "photos":
        return <LocationPhotos />;
      case "description":
        return <LocationDesc />;
      case "housing":
        return <LocationHousing />;
      case "details":
        return <LocationDetails />;
      case "address":
        return <LocationAddress />;
      default:
        return <LocationInfo />;
    }
  }

  const menu = [
    { name: "Info", path: "", id: "info" },
    { name: "Address", path: "", id: "address" },
    { name: "Details", path: "", id: "detail" },
    { name: "Housing", path: "", id: "housing" },
    { name: "Description", path: "", id: "description" },
    { name: "Photos", path: "", id: "photos" },
    { name: "Tags", path: "", id: "tags" },
    { name: "Contact", path: "", id: "contact" },
    { name: "Review", path: "", id: "review" },
  ];

  return (
    <LocationCreateLayout menu={menu}>
      {user.active ? renderLayout() : "You must activate your account"}
    </LocationCreateLayout>
  );
};

export default CreateLocation;

export function setToken(token: string) {
  if (!token) {
    return localStorage.clear();
  }
  const tok = token.split(".");
  tok.forEach((token, i) => {
    localStorage.setItem(`tok${i}`, token);
  });
  localStorage.setItem("tokeDate", Date());
}

export function getToken() {
  const tok1 = localStorage.getItem("tok0");
  const tok2 = localStorage.getItem("tok1");
  const tok3 = localStorage.getItem("tok2");

  const finalTok = `${tok1}.${tok2}.${tok3}`;

  return finalTok;
}

export function deleteTokens() {
  localStorage.removeItem("tok0");
  localStorage.removeItem("tok1");
  localStorage.removeItem("tok2");
  localStorage.removeItem("tokeDate");
}

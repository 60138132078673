import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input, { InputProps } from "shared/Input/Input";

export interface InputWithButtonProps extends InputProps {
  className?: string;
  value?: any;
  onChange?: (e?: any) => void;
  onClick?: (e?: any) => void;
}

const InputWithButton: FC<InputWithButtonProps> = ({
  value,
  onChange,
  onClick,
  onKeyDown,
}) => {
  return (
    <div>
      <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <Input
          type="search"
          id="search"
          className="block w-full py-7 pl-10"
          placeholder="Search"
          required
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <div className="invisible md:visible">
          <ButtonPrimary
            sizeClass="px-6 py-2 rounded-xl"
            onClick={onClick}
            // fontSize="text-sm sm:text-base lg:text-lg font-medium"
            className="!absolute right-2.5 bottom-2.5"
          >
            Search
          </ButtonPrimary>
        </div>
      </div>
      <div className="visible md:invisible pt-2">
        <ButtonPrimary
          sizeClass="px-6 py-3 rounded-xl"
          onClick={onClick}
          // fontSize="text-sm sm:text-base lg:text-lg font-medium"
          className="w-full"
        >
          Search
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default InputWithButton;

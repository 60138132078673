import classNames from "classnames";
import React from "react";
import "./index.scss";

interface ToggleProperties {
  id: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  label?: string;
  "data-on"?: string;
  "data-off"?: string;
  description?: string;
  size?: string;
}

const Toggle: React.FC<ToggleProperties> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event.target.checked);
  };

  const bgClass = classNames("block rounded-full border", {
    "bg-primary-600": props.checked,
    "bg-gray-50": !props.checked,
    "w-10 h-6": props.size === "sm",
    "w-12 h-7": props.size === "md" || !props.size,
    "w-14 h-8": props.size === "lg",
    "w-16 h-9": props.size === "xl",
  });

  const dotClass = classNames(
    "dot border shadow-sm absolute left-1 top-1 bg-white rounded-full transition",
    {
      "w-4 h-4": props.size === "sm",
      "w-5 h-5": props.size === "md" || !props.size,
      "w-6 h-6": props.size === "lg",
      "w-7 h-7": props.size === "xl",
    }
  );
  return (
    <>
      <label className="flex items-center cursor-pointer space-x-3">
        <div className="relative">
          <input
            onChange={onChange}
            type="checkbox"
            id="toggleB"
            className="sr-only"
            checked={props.checked}
          />
          <div className={bgClass} />
          <div className={dotClass} />
        </div>
        {props.label && (
          <div className="ml-3 text-gray-800 font-medium">{props.label}</div>
        )}
      </label>
    </>
  );
};

Toggle.defaultProps = {
  // "data-on": "ON",
  // "data-off": "OFF",
};

export default Toggle;

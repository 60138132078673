import Label from "components/Label/Label";
import PasswordInput from "containers/PasswordInput/PasswordInput";
import useForm from "hooks/useForm";
import React, { useState } from "react";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Progress from "shared/Progress/Progress";
import request from "utils/request";
import CommonLayout from "./CommonLayout";

const AccountPass = () => {
  const { form, updateForm } = useForm();
  const [error, setError] = useState<string>("");

  function handleConfirmPass(e: any) {
    updateForm(e.target);

    if (
      e.target.value.length > 5 &&
      !form.password.startsWith(e.target.value)
    ) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  }

  async function handleSubmit() {
    const res = await request("/account/change/password", {
      method: "post",
      data: { password: form.password },
    });

    if (res.data.status === "success") {
      toast("Password updated", {
        toastId: "passwordProfileChange",
        type: "success",
      });
    } else {
      toast("Something went wrong, please try again later", {
        toastId: "passwordProfileChange",
        type: "error",
      });
    }
  }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Current password</Label>
              <Input
                onChange={updateForm}
                value={form.currentPass}
                id="currentPass"
                type="password"
                className="mt-1.5"
              />
            </div>
            <div>
              <Label>New password</Label>
              <PasswordInput value={form.password} onChange={updateForm} />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input
                value={form.confirmPass}
                onChange={handleConfirmPass}
                id="confirmPass"
                type="password"
                className="mt-1.5"
              />
              {form.confirmPass && (
                <>
                  {/* <Progress color={color} percent={strength} size="sm" /> */}
                  {error && <div className="text-gray-700">{error}</div>}
                </>
              )}
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={handleSubmit}>
                Update password
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;

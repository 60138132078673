import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StateProps, UserFunctionProps } from "redux/types";
import { deleteTokens, setToken } from "utils/authority";
import request from "utils/request";

const initialState: StateProps = {
  data: {},
  status: "idle",
  error: undefined,
};

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async () => {
    const res = await request(`/profile`);
    return res.data;
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // FETCH CURRENT USER
      .addCase(fetchProfile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    // END FETCH CURRENT USER
  },
});

export default accountSlice.reducer;

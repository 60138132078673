import { PlusIcon } from "@heroicons/react/24/solid";
import CommentListing from "./CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addNewReview } from "redux/reducers/reviews/reviewSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Textarea from "shared/Textarea/Textarea";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ImageUploading from "react-images-uploading";
import {
  fetchReviews,
  resetReviews,
  reviewAdded,
} from "redux/reducers/reviews/reviewsSlice";
import { PaginateType, ReviewType } from "types";
import { useAuth } from "hooks/useAuth";
import Modal from "shared/Modal/Modal";
import { ReducersType } from "redux/types";
import Checkbox from "shared/Checkbox/Checkbox";
import SignUpModal from "components/SignUpModal/SignUpModal";
import request from "utils/request";
import { toast } from "react-toastify";

export interface ReviewsProps {
  className?: string;
  data?: [];
  count?: number;
}

export const Reviews: FC<ReviewsProps> = ({ data = [] }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [reviewValue, setReviewValue] = useState("");
  const [reviewStars, setReviewStars] = useState(5);
  const [anonymous, setAnonymous] = useState(false);
  const [showFullReview, setShowFullReview] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState(false);

  const [reviews, setReviews] = useState<any>([]);
  const [paginate, setPaginate] = useState<PaginateType>({ count: 0 });

  const reviewsStatus = useSelector(
    (state: ReducersType) => state.reviews.status
  );

  async function getReviews(pageId?: number) {
    let page = pageId;
    let newReviews: any[] = [];

    if (pageId === 0) {
      page = 1;
    }
    const res: any = await dispatch(
      fetchReviews({ locationId: params.id, page })
    ).unwrap();

    setPaginate(res.data);

    if (pageId === 0) {
      newReviews = [...res.data.reviews];
    } else {
      newReviews = [...reviews, ...res.data.reviews];
    }

    setReviews([...newReviews]);
  }

  useEffect(() => {
    if (reviewsStatus === "idle") {
      getReviews(1);
    }
  }, [reviewsStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetReviews());
      setReviews([]);
      setPaginate({ count: paginate.count });
    };
  }, []);

  function handleFullReview() {
    setShowFullReview(true);
  }

  const resizeTextArea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [reviewValue]);

  async function submitReview() {
    if (!user.active) {
      return setModalVisible(true);
    }
    const data: any = {
      body: reviewValue,
      stars: reviewStars,
      location: params.id,
      images: images,
      anonymous,
    };

    const formData = new FormData();
    for (var key in data) {
      if (key === "images") {
        images.forEach((i) => {
          formData.append("images", i.file);
        });
      } else {
        formData.append(key, data[key]);
      }
    }
    const res = await dispatch(addNewReview({ data: formData })).unwrap();

    if (res.status === "success") {
      dispatch(reviewAdded(res.data));
      setReviews([res.data, ...reviews]);
      setPaginate({ ...paginate, count: paginate.count + 1 });
      setReviewValue("");
      setReviewStars(5);
      setAnonymous(false);
      setImages([]);
    }
  }

  async function handleDelete(id: string) {
    const res = await request("/reviews/" + id, {
      method: "delete",
      data: { locationId: params.id },
    });

    if (res.data.status === "success") {
      // dispatch(reviewDeleted(res.data._id));
      const newData = reviews.filter((x: any) => x._id !== id);
      setReviews(newData);
      setPaginate({ ...paginate, count: paginate.count - 1 });
      toast(res.data.message, {
        toastId: "commentDeleted" + res.data._id,
        type: res.data.status,
      });
    }
  }

  function handleChange(e: any) {
    setReviewValue(e.target.value);
  }

  const handleImgChange = (imageList: any) => {
    setImages(imageList);
  };

  function showMoreReviews() {
    if (paginate.nextPage) {
      getReviews(paginate.nextPage);
    } else {
      getReviews(0);
    }
  }

  return (
    <>
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">
          Reviews ({paginate.count} reviews)
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-1">
          <div className="flex items-center justify-between">
            <FiveStartIconForRate
              onChange={(num) => setReviewStars(num)}
              iconClass="w-6 h-6"
              className="space-x-0.5 mb-3"
            />
            <Checkbox
              onChange={(e) => setAnonymous(e.checked)}
              name="anonymous"
              sizeClass="h-4 w-4"
              label="Post anonymously"
            />
          </div>
          <div className="relative flex">
            <Textarea
              ref={textareaRef}
              value={reviewValue}
              onChange={handleChange}
              rows={2}
              style={{ height: "70px" }}
              className="resize-none overflow-y-hidden px-5 pr-36 py-5"
              placeholder="Leave a review ..."
            />
            <div className="absolute right-[16px] bottom-[16px]">
              <ButtonPrimary onClick={submitReview} className="space-x-2">
                Submit
              </ButtonPrimary>
            </div>
          </div>
          <ImageUploading
            multiple
            value={images}
            onChange={handleImgChange}
            maxNumber={10}
            dataURLKey="data_url"
            acceptType={["jpg", "jpeg", "png"]}
            maxFileSize={500000}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
              errors,
            }) => (
              // write your building UI
              <div className="">
                <div className="flex items-center justify-between my-2">
                  <span
                    onClick={onImageUpload}
                    className="font-semibold text-neutral-700 dark:text-neutral-300 flex items-center cursor-pointer hover:underline"
                  >
                    <PlusIcon className="w-4 h-4 [&>path]:stroke-[3]" /> Add
                    Attachments
                  </span>
                  {/* <span
                    onClick={handleFullReview}
                    className="font-semibold text-neutral-700 dark:text-neutral-300 flex items-center cursor-pointer hover:underline"
                  >
                    Create full review
                  </span> */}
                </div>
                {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                <div className="flex items-center space-x-4">
                  {imageList.map((image, index) => {
                    return (
                      <div
                        key={index}
                        className="w-28 h-28 border relative rounded-xl"
                      >
                        <div
                          className="absolute inset-0 bg-cover bg-center z-0 rounded-xl"
                          style={{
                            backgroundImage: `url('${image.data_url}')`,
                          }}
                        ></div>
                        <div className="opacity-0 hover:opacity-100 duration-300 rounded-xl absolute inset-0 z-10 flex justify-end text-6xl text-white font-semibold">
                          <XMarkIcon
                            onClick={() => onImageRemove(index)}
                            className="w-6 h-6 z-30 stroke-2 m-2 cursor-pointer"
                          />
                          <div className="bg-black opacity-30 rounded-xl w-full h-full absolute" />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {errors && (
                  <div className="mt-2 text-red-600">
                    {errors.maxNumber && (
                      <span>Number of selected images exceed maxNumber</span>
                    )}
                    {errors.acceptType && (
                      <span>Your selected file type is not allow</span>
                    )}
                    {errors.maxFileSize && (
                      <span>Selected file size exceed maxFileSize</span>
                    )}
                  </div>
                )}
              </div>
            )}
          </ImageUploading>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {reviews.length > 0 ? (
            <>
              {reviews.map((review: ReviewType, i: string) => {
                return (
                  <CommentListing
                    onImageClick={() => ""}
                    key={i}
                    data={review}
                    className="py-8"
                    handleDelete={handleDelete}
                  />
                );
              })}
              {paginate.count > 5 && (
                <div className="pt-8">
                  <ButtonSecondary
                    loading={reviewsStatus === "loading"}
                    onClick={showMoreReviews}
                  >
                    {paginate.hasNextPage ? "Show more reviews" : "Close"}
                  </ButtonSecondary>
                </div>
              )}
            </>
          ) : (
            <div className="p-5 flex justify-center font-semibold">
              Be the first to leave a review
            </div>
          )}
        </div>
      </div>
      <SignUpModal visible={modalVisible} setVisible={setModalVisible} />
    </>
  );
};

import { isObject } from "lodash";
import LocationCard from "pages/Home/LocationCardVertical";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { LocationCreateProps } from ".";

export interface LocationReviewProps extends LocationCreateProps {}

const LocationReview: FC<LocationReviewProps> = ({ form, toPage, ...e }) => {
  let images = [];
  let experience = form.experience;
  let address = {};
  let staffSize = "";
  const coverImage = Array.isArray(form.coverImage)
    ? form.coverImage?.[0]?.uri || form.coverImage?.[0]?.url
    : form.coverImage?.uri || form.coverImage?.url;

  if (form.images) {
    images = form.images
      ?.filter((i: any, index: number) => i && index < 4)
      .map((i: any) => i.uri || i.url);

    images.unshift(coverImage);
  } else {
    images = [coverImage];
  }

  if (experience && isObject(experience)) {
    experience = form.experience?.value;
  }

  if (form.staffSize && isObject(form.staffSize)) {
    staffSize = form.staffSize.value;
  }

  if (form.address) {
    address = {
      street: form.address?.street,
      city: form.address?.city,
      state: form.address?.state,
    };
  } else {
    address = {
      street: form.street,
      city: form.city,
      state: form.state,
    };
  }

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold">Congratulations 🎉</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Excellent, congratulations on completing the listing, it is waiting to
          be reviewed for publication
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {/* FORM */}
      <div>
        <h3 className="text-lg font-semibold">This is your listing</h3>
        <div className="max-w-xs">
          <LocationCard
            className="mt-8"
            onClick={() => console.log("NAVIGATE")}
            data={{
              ...form,
              staffSize,
              address,
              experience,
              images: images || [],
              title: form.name,
              stars: 0,
            }}
          />
        </div>
        <div className="flex items-center space-x-5 mt-8">
          <ButtonSecondary onClick={() => toPage && toPage(0)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            <span className="ml-3">Edit</span>
          </ButtonSecondary>

          <ButtonPrimary onClick={() => e.onSubmit && e.onSubmit("draft")}>
            <span>Save Draft</span>
          </ButtonPrimary>
        </div>
      </div>
      {/*  */}
    </>
  );
};

export default LocationReview;

import Label from "components/Label/Label";
import PasswordInput from "containers/PasswordInput/PasswordInput";
import useForm from "hooks/useForm";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Progress from "shared/Progress/Progress";
import request from "utils/request";
import validator from "validator";

export interface ChangePasswordFormProps {
  account?: boolean;
  form: any;
  updateForm: (e: any) => void;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  account,
  form,
  updateForm,
}) => {
  const [error, setError] = useState<string>("");

  function handleConfirmPass(e: any) {
    updateForm(e.target);

    if (
      e.target.value.length > 5 &&
      !form.password.startsWith(e.target.value)
    ) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  }

  return (
    <>
      {account && (
        <div>
          <Label>Current password</Label>
          <Input
            onChange={updateForm}
            value={form.currentPass}
            id="currentPass"
            type="password"
            className="mt-1.5"
          />
        </div>
      )}
      <div>
        <Label>New password</Label>
        <PasswordInput value={form.password} onChange={updateForm} />
      </div>
      <div>
        <Label>Confirm password</Label>
        <Input
          value={form.confirmPass}
          onChange={handleConfirmPass}
          id="confirmPass"
          type="password"
          className="mt-1.5"
        />
        {form.confirmPass && (
          <>
            {/* <Progress color={color} percent={strength} size="sm" /> */}
            {error && <div className="text-gray-700">{error}</div>}
          </>
        )}
      </div>
    </>
  );
};

export default ChangePasswordForm;

import { useAuth } from "hooks/useAuth";
import useForm from "hooks/useForm";
import { isObject } from "lodash";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  addNewLocation,
  fetchLocation,
  resetLocation,
  updateLocation,
} from "redux/reducers/locations/locationSlice";
import { ReducersType } from "redux/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { getFullAddress } from ".";
import classNames from "classnames";

const PAGE_COUNT = 9;

const pages = [
  "",
  "address",
  "details",
  "housing",
  "description",
  "photos",
  "tags",
  "contact",
  "review",
];

export interface TabLayoutProps {
  name: string;
  id: string;
  path: string;
}

export interface LocationCreateLayoutProps {
  children?: React.ReactNode;
  menu?: TabLayoutProps[];
}
const LocationCreateLayout: FC<LocationCreateLayoutProps> = ({
  children,
  menu,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const loc = useLocation();
  const form = useForm();
  const { user } = useAuth();
  const [activePage, setActivePage] = useState<number>(0);
  const [nextText, setNextText] = useState<string>("");
  const [stop, setStop] = useState<boolean>(false);
  const [message, setMessage] = useState<{ [key: string]: any }>({});

  const location = useSelector((state: ReducersType) => state.location);

  const isValid = form.form.type && form.form.name;
  useEffect(() => {
    if (params.id && location.status === "idle") {
      dispatch(fetchLocation({ id: params.id }));
    }
  }, [params.id, location.status]);

  useEffect(() => {
    if (loc.pathname.includes("/edit") && location.data._id) {
      form.setFormInit(location.data);
    }
  }, [location.data._id]);

  useEffect(() => {
    if (loc.pathname.includes("create")) {
      form.clearForm();
    }
  }, [loc.pathname]);

  useLayoutEffect(() => {
    const index = pages.findIndex((i) => {
      return i === loc.search.substring(6);
    });

    if (!params.id && !isValid) {
      setActivePage(0);
      loc.search = "";
      navigate("/locations/create");
      setStop(true);
    } else {
      setStop(false);
      setActivePage(index);
    }

    if (activePage + 1 < PAGE_COUNT) {
      setNextText("Next");
    } else {
      setNextText("Submit");
    }
  }, [activePage, form.form.type, form.form.name, loc.search]);

  async function initCreate() {
    const formData = form.form;
    const submitData = {
      name: formData.name,
      type: formData.type?.value,
      active: false,
      status: "creating",
    };

    const res = await dispatch(addNewLocation({ data: submitData })).unwrap();

    if (res.status === "success") {
      setMessage({});
      setStop(false);
      navigate(`/locations/${res.data._id}/edit?page=address`);
      setActivePage(1);
    }

    if (res.status === "warning") {
      setMessage(res);
      setStop(true);
    }
  }

  function renderData(obj: any) {
    const res: any = {};

    for (let key in obj) {
      let item = obj[key];

      if (isObject(item)) {
        item = obj[key];
        res[key] = item.value && item?.value;
      }
      if (Array.isArray(item) && key !== "images" && key !== "coverImage") {
        res[key] = item
          ?.map((i: any) => {
            return typeof i === "string" ? i : i.value;
          })
          .join(", ");
      }
      if (typeof item === "string") {
        res[key] = item;
      }
    }

    return res;
  }

  async function handleSubmit(e?: any) {
    const formData = form.form;
    delete formData.reviews;
    const submitData: any = renderData(formData);

    if (formData.coverImage) {
      let cover = formData.coverImage;

      if (Array.isArray(cover)) {
        if (cover[0]?.file) {
          cover = cover[0].file;
        }
        if (cover[0]?.uri && !cover[0]._id) {
          cover = cover[0].uri;
        }
      }
      console.log(submitData.coverImage);

      submitData.coverImage = cover._id || cover;
    } else {
      submitData.coverImage = null;
    }

    if (formData.images) {
      let images = formData.images;

      if (Array.isArray(images)) {
        images = formData.images
          .filter((img: any) => {
            if (img.file || img.uri) return !img._id;
            if (!img.uri || !img.url) return false;
            return !img._id;
          })
          .map((i: any) => i?.uri || i.file);

        images = images.length > 0 ? images : null;
      }

      submitData.images = images;
    } else {
      submitData.images = null;
    }

    if (e === "draft") {
      submitData.status = "draft";
    }
    if (e === "publish") {
      submitData.status = "published";
      submitData.active = true;
    }

    console.log(submitData);

    const uploadData = await getFormData(submitData);

    const res = await dispatch(
      updateLocation({ id: params.id, data: uploadData })
    ).unwrap();

    // if (res.status === "success") {
    //   navigate("/locations/" + res.data._id);
    // }
  }

  function removeEmpty(obj: any): { [key: string]: any } {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  }

  function getFormData(e: any) {
    return new Promise((resolve) => {
      const removed = removeEmpty(e);
      const updateData = new FormData();

      for (var key in removed) {
        if (key === "images") {
          removed.images.forEach((i: any) => {
            updateData.append("images", i);
          });
        } else {
          updateData.append(key, removed[key]);
        }
      }
      resolve(updateData);
    });
  }

  function toPage(pageIndex: number) {
    loc.search = "";
    navigate(getPageUrl(pageIndex));
    setActivePage(pageIndex);
  }

  function getPageUrl(index: number): string {
    let path = "/locations/";
    if (params.id) {
      path = path + params.id + "/edit";
    } else {
      path = path + "create";
    }

    return path + "?page=" + pages[index];
  }

  function handleNextPage(pageIndex?: number) {
    if (stop) return;
    const nextPageNumber = pageIndex
      ? pageIndex
      : pageIndex === 0
      ? 0
      : activePage + 1;
    const nextUrl = getPageUrl(nextPageNumber);
    setActivePage(nextPageNumber);
    navigate(nextUrl);
    window.scroll(0, 0);
  }

  function handleBackPage() {
    if (stop) return;
    const backPageNumber = activePage - 1;
    const backUrl = getPageUrl(backPageNumber);
    setActivePage(backPageNumber);
    navigate(backUrl);
    window.scroll(0, 0);
  }

  function next() {
    if (loc.pathname.includes("edit")) {
      if (activePage + 1 === PAGE_COUNT) {
        return handleSubmit("publish");
      }
      return handleNextPage();
    }
    return activePage === 0 ? initCreate() : handleNextPage();
  }

  function back() {
    if (activePage === 0 && loc.pathname.includes("create")) {
      navigate("/locations");
    }

    return activePage === 0
      ? navigate("/locations/" + location.data._id || "")
      : handleBackPage();
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        location,
        params,
        toPage,
        onSubmit: handleSubmit,
        ...form,
      });
    }
    return child;
  });

  function handleMenu(pageIndex: number) {
    handleNextPage(pageIndex);
  }

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-20 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{activePage + 1}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / {PAGE_COUNT}
          </span>
        </div>

        {message?.message && (
          <div>
            <div className="text-lg font-semibold">{message.message}</div>
            <p>
              Please append the name with a state or city, "The Cabin -
              California"
            </p>
            <div className="mt-2">
              If you are the owner of this location, please follow the
              instrcutions{" "}
              <Link className="text-primary-600 hover:underline" to="/help">
                here
              </Link>
            </div>
            <div>
              If you are trying to add this location to write a review, please
              click{" "}
              <Link
                className="text-primary-600 hover:underline"
                to={`/locations/${message.data._id}`}
              >
                here
              </Link>
            </div>
          </div>
        )}
        <div className="listingSection__wrap ">
          {menu && (
            <div className="border-b border-neutral-200 dark:border-neutral-700 pt-0 bg-white dark:bg-neutral-800">
              <div className="">
                <div className="flex space-x-3 md:space-x-4 overflow-x-auto hiddenScrollbar">
                  {menu.map((item, i) => {
                    const isActive = activePage === i;
                    const menuTabClass = classNames(
                      "block py-5 md:py-4 flex-shrink-0 cursor-pointer",
                      { "border-primary-500 border-b-2": isActive }
                    );

                    return (
                      <div
                        key={i}
                        onClick={() => handleMenu(i)}
                        className={menuTabClass}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {childrenWithProps}
        </div>
        <div className="flex justify-between">
          <div>
            <ButtonSecondary
              onClick={() => navigate("/locations/" + location.data._id)}
            >
              Cancel
            </ButtonSecondary>
          </div>
          <div className="space-x-5">
            {activePage !== 0 && (
              <ButtonSecondary onClick={back}>
                {activePage > 0 ? "Go back" : "Cancel"}
              </ButtonSecondary>
            )}
            <ButtonPrimary disabled={!isValid} onClick={next}>
              {nextText || "Continue"}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationCreateLayout;

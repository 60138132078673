import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FC, Fragment, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface ModalProps {
  visible: boolean;
  setVisible: (e: boolean) => void;
  content?: any;
  children?: any;
  title?: any;
  footer?: any;
  size?: string;
  footerPosition?: string;
}

const Modal: FC<ModalProps> = ({
  visible,
  setVisible,
  title,
  footer,
  content,
  children,
  size,
  footerPosition,
}) => {
  let isOpen = visible;

  function closeModal() {
    setVisible && setVisible(false);
  }

  const footerClasses = classNames(
    "flex items-center space-x-2 p-2 rounded-b border-t border-gray-200",
    {
      "justify-start": footerPosition === "left",
    },
    { "justify-center": footerPosition === "center" },
    { "justify-end": footerPosition === "right" || !footerPosition }
  );

  const panelWrap = classNames(
    "w-full flex flex-col space-4 transform overflow-visible min-h-[50px]",
    "rounded-xl bg-white text-left align-middle shadow-xl transition-all",
    {
      "md:w-4/12": size === "sm",
      "md:w-6/12": size === "md",
      "md:w-9/12": size === "lg",
      "md:11/12 lg:w-4/5 ": size === "xl",
    }
  );

  const componentFooter = footer ? (
    footer
  ) : (
    <ButtonPrimary id="modal-footer" sizeClass="px-4 py-2" onClick={closeModal}>
      {footer ? footer : "Close"}
    </ButtonPrimary>
  );

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto p-2">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={panelWrap}>
                  <div className="flex justify-between items-center p-2 rounded-t border-b">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 mx-3 my-2"
                    >
                      {title}
                    </Dialog.Title>
                    <button
                      type="button"
                      className="text-gray-400 z-50 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center"
                      data-modal-toggle="small-modal"
                      onClick={closeModal}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-3 lg:p-6 space-y-6">
                    {content || children}
                  </div>
                  <div className={footerClasses}>{componentFooter}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modal;

import { FC } from "react";
import { Helmet } from "react-helmet";
import Banner from "shared/Banner/Banner";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface LoginLayoutProps {
  title?: string;
  buttonTitle?: string;
  className?: string;
  children: React.ReactNode;
  onSubmit: () => void;
  footer?: React.ReactNode | string;
  buttonDisabled?: boolean;
  id?: string;
  showBanner?: boolean;
  loading?: boolean;
  banner?: { title: string; content: string };
}

const LoginLayout: FC<LoginLayoutProps> = ({
  className = "",
  title,
  children,
  footer,
  buttonDisabled,
  buttonTitle,
  onSubmit,
  showBanner,
  banner,
  id,
  loading,
}) => {
  function handleSubmit() {
    onSubmit();
  }

  return (
    <div className={`nc-LoginLayout  ${className}`} data-nc-id={id || title}>
      {title && (
        <Helmet>
          <title>{title} || Upseasonal</title>
        </Helmet>
      )}
      <div className="container mb-24 lg:mb-32">
        {banner && showBanner && (
          <Banner title={banner.title} content={banner.content} />
        )}
        {title && (
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {title}
          </h2>
        )}
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid grid-cols-1 gap-6">
            {children}
            <ButtonPrimary
              loading={loading}
              disabled={buttonDisabled}
              onClick={handleSubmit}
            >
              {buttonTitle ? buttonTitle : "Submit"}
            </ButtonPrimary>
          </div>
          {footer && (
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              {footer}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;

// return (
//   <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
//     <div className="container mb-24 lg:mb-32">
// {activated && (
//   <Banner
//     title="Account Activated"
//     content="Your account has been activated, you may now login"
//   />
// )}
//       <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
//         Login
//       </h2>
//       <div className="max-w-md mx-auto space-y-6">
//         {/* FORM */}
//         <div className="grid grid-cols-1 gap-6">
//
//           <ButtonPrimary
//             loading={loading}
//             disabled={loading || !isValid}
//             onClick={handleSubmit}
//           >
//             Continue
//           </ButtonPrimary>
//         </div>
//         <span className="block text-center text-neutral-700 dark:text-neutral-300">
//           New user? {` `}
//           <Link className="text-primary-600" to="/signup">
//             Create an account
//           </Link>
//         </span>
//         {/* OR */}
//         {/* <div className="relative text-center">
//           <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
//             OR
//           </span>
//           <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
//         </div>
//         <div className="grid gap-3">
//           {loginSocials.map((item, index) => (
//             <a
//               key={index}
//               href={item.href}
//               className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
//             >
//               <img
//                 className="flex-shrink-0"
//                 src={item.icon}
//                 alt={item.name}
//               />
//               <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
//                 {item.name}
//               </h3>
//             </a>
//           ))}
//         </div> */}
//       </div>
//     </div>
//   </div>
// );

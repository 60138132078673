import React, { useEffect, useState } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import Select, { OptionsProps, SelectProps } from "shared/Select/Select";

export interface MultiSelectProps extends SelectProps {}

const MultiSelect: React.FC<MultiSelectProps> = ({
  id,
  onChange,
  options = [],
  value,
  ...e
}) => {
  const defaultValue = e.defaultValue;
  // typeof e.defaultValue === "string" &&
  // e.defaultValue.split(", ").map((i: string) => ({ label: i, value: i }));

  const [selectValue, setSelectValue] = useState(
    value || {
      label: "Select an option",
      value: "",
    }
  );
  const [selected, setSelected] = useState<OptionsProps[]>(value || []);

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
      setSelectValue(defaultValue);
    }
  }, [Array.isArray(defaultValue)]);

  function handleChange(e: OptionsProps) {
    const newValue = e;
    const checked = selected.findIndex((v) => {
      return v.value === e.value;
    });

    if (checked >= 0) {
      const newArray = selected.filter((item) => item.value !== e.value);
      setSelected(newArray);
      onChange &&
        onChange({
          label: "",
          value: newArray,
          id: id,
        });
      if (newArray.length === 0) {
        return setSelectValue({ label: "Select an option", value: "" });
      }
    } else {
      setSelected([...selected, newValue]);
      onChange &&
        onChange({
          label: "",
          value: [...selected, newValue],
          id: id,
        });
    }
  }

  function renderOptions(): any[] {
    return options.map((item, i) => {
      const checked =
        selected.filter((v) => {
          return v.value === item.value;
        }).length > 0;

      return {
        label: (
          <div className="flex items-center space-x-2">
            <Checkbox checked={checked} name={item.label} sizeClass="w-4 h-4" />
            <span>{item.label}</span>
          </div>
        ),
        value: item.value,
        id: item.id,
      };
    });
  }

  const label = selected
    .map((item) => item?.value)
    .filter((item) => item !== "");

  return (
    <div className="w-full">
      <Select
        id={id}
        onChange={handleChange}
        options={renderOptions()}
        value={selectValue}
        label={label.length === 0 ? null : label.join(", ")}
      />
    </div>
  );
};

export default MultiSelect;

import React, { FC, ReactNode, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import { LocationProps } from "../types";
import LocationCard from "components/LocationCard/LocationCard";
import InfiniteScroll from "react-infinite-scroll-component";
import LocationCardVertical from "../../Home/LocationCardVertical";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import SignUpModal from "components/SignUpModal/SignUpModal";
import { useAuth } from "hooks/useAuth";
import { LocationDataProps } from "types";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
//
export interface SectionGridLocationsProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  data: any;
  getData: (e: any) => void;
  emptyData?: any;
}

const SectionGridLocations: FC<SectionGridLocationsProps> = ({
  gridClass = "",
  data = { count: 0 },
  getData,
  emptyData,
}) => {
  const renderCard = (item: LocationDataProps, index: number) => {
    let location = { ...item, images: item.images || [] };
    let images = [...location.images];
    location.href = "/locations/" + location._id;
    let coverImage = location.coverImage;

    if (Array.isArray(images) && coverImage) {
      images.unshift(coverImage);
    } else {
      images = []; // DEMO_DATA[0].galleryImgs;
    }

    location.images = [...images].map((i) =>
      typeof i === "string" ? i : i.uri || i.url
    );

    location.title = location.name || location.title;

    // return <LocationCard key={location._id} data={{ ...location }} />;

    return <LocationCard key={index} className="h-full" data={location} />;
  };

  function fetchMoreData() {
    getData({ page: data.nextPage });
  }

  const endMessage = (
    <div className="mt-6 mb-24 flex justify-center">
      {!data.count ? "Loading..." : "You have reached the end"}
    </div>
  );

  return (
    <div className="nc-SectionGridLocations relative">
      {data.count === 0 ? (
        emptyData
      ) : (
        <InfiniteScroll
          dataLength={data.count || 0}
          next={fetchMoreData}
          hasMore={data.hasNextPage}
          loader={<h4>Loading...</h4>}
          style={{ height: "inherit", overflow: "inherit" }}
          endMessage={endMessage}
        >
          <div
            className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}
          >
            {data.locations.map(renderCard)}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default SectionGridLocations;

import { GlobeAltIcon } from "@heroicons/react/24/outline";
import StartRating from "components/StartRating/StartRating";
import { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { Amenities_demos } from ".";
import { LocationProps } from "../types";
// import LikeSaveBtns from "./LikeSaveBtns";

export interface MainDetailsProps {
  info: LocationProps;
}

const MainDetails: FC<MainDetailsProps> = ({ info }) => {
  const details = [
    { name: `Seasons: ${info.season}`, icon: "la-calendar" },
    { name: `Housing: ${info.housing}`, icon: "la-home" },
    { name: `${info.petFriendly}`, icon: "la-paw" },
    { name: `Transportation: ${info.transportation}`, icon: "la-car" },
    { name: `Meals: ${info.meals}`, icon: "la-utensils" },
    { name: `Cell service: ${info.cellService}`, icon: "la-phone" },
    { name: `Internet Access: ${info.internetAccess}`, icon: "la-spa" },
    { name: `Guest Size: ${info.guestSize}`, icon: "la-users" },
    { name: `Staff Size: ${info.staffSize}`, icon: "la-people-carry" },
  ];

  return (
    <div className="listingSection__wrap !space-y-6">
      <div className="flex justify-between items-center">
        {/* Tags - max based on width */}
        {info.experience && <Badge color="green" name={info.experience} />}
        {/* <LikeSaveBtns /> */}
      </div>
      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {info.title || info.name}
      </h2>
      <div className="flex items-center space-x-4">
        <StartRating reviewCount={info.reviewCount} point={info.stars} />
        {info.address && (
          <>
            <span>·</span>
            <span>
              <i className="las la-map-marker-alt"></i>
              <span className="ml-1">
                {typeof info.address === "string"
                  ? info.address
                  : `${info.address.city}, ${info.address.state}`}
              </span>
            </span>
          </>
        )}
      </div>
      {(info.email || info.phone || info.website) && (
        <div className="flex items-center space-x-4 text-sm">
          {info.email && <span className="">{info.email}</span>}
          {info.phone && <span className="">{info.phone}</span>}
          {info.website && (
            <span className="hover:underline cursor-pointer flex items-center space-x-1">
              <GlobeAltIcon className="w-4 h-4" />
              <a href={info.website} className="text-primary-600">
                {info.website}
              </a>
            </span>
          )}
        </div>
      )}
      {/* <div className="flex items-center">
        <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
        <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
          Hosted by{" "}
          <span className="text-neutral-900 dark:text-neutral-200 font-medium">
            Kevin Francis
          </span>
        </span>
      </div> */}
      {/* 5 */}
      <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
      {/* 6 */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
        {details
          .filter((item, i) => {
            if (!item.name || item.name.includes("undefined")) return false;
            return i < 12;
          })
          .map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className="">{item.name}</span>
            </div>
          ))}
      </div>{" "}
      {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
        <div className="flex items-center space-x-3 ">
          <i className=" las la-user text-2xl "></i>
          <span className="">
            6 <span className="hidden sm:inline-block">guests</span>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className=" las la-bed text-2xl"></i>
          <span className=" ">
            6 <span className="hidden sm:inline-block">beds</span>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className=" las la-bath text-2xl"></i>
          <span className=" ">
            3 <span className="hidden sm:inline-block">baths</span>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className=" las la-door-open text-2xl"></i>
          <span className=" ">
            2 <span className="hidden sm:inline-block">bedrooms</span>
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default MainDetails;

export interface ExperienceType {
  id: string;
  name: string;
  title?: string;
  count?: number;
  thumbnail?: string;
}
// desert, mountains, river, sea side
export const experiences: ExperienceType[] = [
  {
    id: "Desert",
    name: "Desert",
    title: "Enjoy the sand",
    count: 0,
    thumbnail:
      "https://www.undercanvas.com/wp-content/uploads/2021/07/002-scaled.jpg",
  },
  {
    id: "Mountains",
    name: "Mountains",
    title: "Breath in the mountain air",
    count: 0,
    thumbnail:
      "https://www.usnews.com/dims4/USNEWS/4360844/2147483647/thumbnail/970x647/quality/85/?url=https%3A%2F%2Fwww.usnews.com%2Fcmsmedia%2F1c%2Ffb%2F3a50a8cb44b4910696546d82f23d%2Fintro-four-seasons-resort-and-residences-jackson-hole.jpg",
  },
  {
    id: "River",
    name: "River",
    title: "Listen to water crashing",
    count: 0,
    thumbnail:
      "https://cdn7.dissolve.com/p/D1061_259_095/D1061_259_095_1200.jpg",
  },
  {
    id: "Sea Side",
    name: "Sea Side",
    title: "Stay cool by the sea",
    count: 0,
    thumbnail:
      "https://s3.amazonaws.com/uploads.opalcollection.com/app/uploads/sites/7/2022/06/13165755/Harborside-Aerial-Shot-1024x683.jpeg",
  },
];

import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import {
  fetchCurrentUser,
  selectCurrentUser,
  userLogin,
  userLogout,
  userRegister,
} from "../redux/reducers/accountSlice";
import { deleteTokens } from "../utils/authority";
// import PageLoading from "../components/PageLoading";
import { toast } from "react-toastify";
import { AppDispatch } from "index";
import { UserFunctionProps } from "redux/types";
import { UserProps } from "types";

interface AuthProviderProps {
  children: ReactNode;
}

export type AuthContextProps = {
  user: UserProps;
  login: (data: UserFunctionProps) => void;
  register: (data: UserFunctionProps) => void;
  logout: () => void;
  loading: boolean;
};

const defaultState = {
  user: { active: false },
  login: () => null,
  register: () => null,
  logout: () => null,
  loading: true,
};

const AuthContext = createContext<AuthContextProps>(defaultState);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", { active: false });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const accountStatus = useSelector<{ [key: string]: any }>(
    (state) => state.account.status
  );
  const currentUser = useSelector(selectCurrentUser);

  const loading = isLoading;

  useEffect(() => {
    (async () => {
      if (accountStatus === "idle") {
        try {
          const { user } = await dispatch(fetchCurrentUser()).unwrap();
          setUser(user);
          navigate(location);
        } catch (err) {}
      }
    })();
  }, [accountStatus, dispatch, currentUser, setUser, navigate, location]);

  const login = async (data: UserFunctionProps) => {
    setIsLoading(true);
    const res = await dispatch(userLogin(data)).unwrap();

    setIsLoading(false);
    if (res.status) {
      toast(res.message, {
        toastId: "loginToast",
        type: res.status,
      });
    }
    if (res.user) {
      setUser(res.user);
      navigate("/", { replace: true });
    }
  };

  const register = async (data: UserFunctionProps) => {
    setIsLoading(true);
    const res = await dispatch(userRegister(data)).unwrap();

    setIsLoading(false);
    if (res.status) {
      toast(res.message, {
        toastId: "userRegister",
        type: res.status,
      });
    }
    if (res.user.email) {
      setUser(res.user);
      navigate("/account", { replace: true });
    }
  };

  const logout = () => {
    setIsLoading(true);
    dispatch(userLogout());
    setUser({ active: false });
    deleteTokens();
    navigate("/login", { replace: true });
    setIsLoading(false);
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      loading,
      register,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
      {/* {loading ? <PageLoading /> : children} */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

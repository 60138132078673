import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { FC, Fragment, useState, useEffect } from "react";

export interface TagProps {
  name: string;
}

export interface TagDropdownProps {
  dropdown: TagProps[];
  id: string;
  onChange: (item: TagProps) => void;
  value?: string;
  placeholder?: string;
  color?: string;
}

const TagDropdown: FC<TagDropdownProps> = ({
  dropdown,
  id,
  onChange,
  value,
  placeholder = "Select tag",
  color,
}) => {
  const [selected, setSelected] = useState(value || placeholder);
  const active = selected !== placeholder;
  const wrapperClasses = classNames(
    "mr-2 my-1 sm:mr-4 border border-neutral-300 dark:border-neutral-700 rounded-full",
    {
      "bg-primary-600 hover:bg-primary-700":
        active && (color === "primary" || !color),
    },
    { "bg-amber-400 hover:bg-amber-700": active && color === "yellow" }
  );

  useEffect(() => {
    value && setSelected(value);
    !value && setSelected(placeholder);
  }, [value]);

  function handleChange(e: any) {
    onChange(e);
  }

  return (
    <div className={wrapperClasses}>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              onClick={() => document.querySelector("html")?.click()}
            >
              <span
                className={classNames({
                  "text-neutral-50": active,
                })}
              >{`${selected}`}</span>
              {active ? (
                <XMarkIcon
                  onClick={(e) => {
                    e.preventDefault();
                    setSelected(placeholder);
                    handleChange({ name: "" });
                  }}
                  className={classNames(
                    "ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150",
                    {
                      "text-neutral-50": active,
                    }
                  )}
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className={classNames(
                    "ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150",
                    {
                      "text-neutral-50": active,
                    }
                  )}
                  aria-hidden="true"
                />
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="left-full absolute z-10 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 sm:px-0 ">
                <div className="max-h-52 overflow-y-scroll rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 ">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                    {dropdown.map((item) => (
                      <div
                        key={item.name}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelected(item.name);
                          handleChange(item);
                          close();
                        }}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <p className="text-sm font-medium cursor-default">
                          {item.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default TagDropdown;

import React, { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import classNames from "classnames";

export interface HeaderFilterProps {
  tabActive?: string;
  tabs?: string[];
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickTab: (item: string) => void;
  hideTabs?: boolean;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive = "",
  tabs,
  subHeading = "",
  heading = "🎈 Latest Articles",
  onClickTab,
  hideTabs,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item);
    setTabActiveState(item);
  };

  const showTabs = !hideTabs && tabs;
  const wrapperClasses = classNames(
    "flex flex-col relative",
    { "mb-8": showTabs },
    { "mb-3": !showTabs }
  );

  return (
    <div className={wrapperClasses}>
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between">
        {showTabs && (
          <Nav
            className="sm:space-x-2"
            containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
          >
            {tabs.map((item, index) => (
              <NavItem
                key={index}
                isActive={tabActiveState === item}
                onClick={() => handleClickTab(item)}
              >
                {item}
              </NavItem>
            ))}
          </Nav>
        )}
        <span className="hidden sm:block flex-shrink-0">
          <ButtonSecondary className="!leading-none">
            <span>View all</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default HeaderFilter;

import { configureStore } from "@reduxjs/toolkit";
import profileReducer from "./reducers/profileSlice";
import accountReducer from "./reducers/accountSlice";
import locations from "./reducers/locations";
import reviews from "./reducers/reviews";

export default configureStore({
  reducer: {
    profile: profileReducer,
    account: accountReducer,
    ...locations,
    ...reviews,
  },
});

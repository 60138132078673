import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { LocationProps } from "pages/Locations/types";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { LocationDataProps } from "types";

export interface LocationCardProps {
  className?: string;
  data: LocationDataProps;
}

// const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const LocationCard: FC<LocationCardProps> = ({
  className = "",
  data, //= DEMO_DATA,
}) => {
  const {
    images,
    title,
    href,
    // isAds,
    stars,
    reviewCount,
    id,
    staffSize,
    experience,
  } = data;

  const renderSliderGallery = () => {
    if (!images) return;
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={images}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`LocationCard_${id}`}
          href={href}
        />

        {/* V2 */}
        {/* {saleOff && (
          <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
        )} */}
      </div>
    );
  };

  const renderInfo = () => {
    // Render City State
    return (
      <div className="flex space-x-2">
        {/* {staffSize && (
          <div className="flex items-center space-x-2">
            <span className="hidden sm:inline-block">
              <UserGroupIcon className="w-4 h-4" />
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {staffSize} employees
            </span>
          </div>
        )} */}

        {/* <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            3 baths
          </span>
        </div>

        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            1200 Sq. Fit
          </span>
        </div> */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          {experience && (
            <div className="inline-flex space-x-3">
              {/*  BADGES ARE TAGS BASED ON LOCATIONS: Desert, Forest, East Coast, etc... */}
              <Badge
                name={
                  <div className="flex items-center">
                    {/* <i className="text-sm las la-mountain"></i> */}
                    <span
                    //  className="ml-1"
                    >
                      {experience}
                    </span>
                  </div>
                }
                color="green"
              />
              {/* <Badge
              name={
                <div className="flex items-center">
                <i className="text-sm las la-user-friends"></i>
                <span className="ml-1">Family</span>
                </div>
              }
              color="yellow"
            /> */}
            </div>
          )}
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{title}</span>
            </h2>
          </div>
          {renderInfo()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            <StartRating reviewCount={reviewCount} point={stars} />
            {/* <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
              {`${price},000`}
            </span> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-LocationCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="LocationCard"
    >
      <Link to={href} className="absolute inset-0"></Link>
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      {/* V2 */}
      {/* <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-600 dark:text-neutral-400`}
        isLiked={like}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      /> */}
    </div>
  );
};

export default LocationCard;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StateProps } from "redux/types";
import request, { renderQueries } from "../../../utils/request";

const initialState: StateProps = {
  data: {},
  status: "idle",
  error: null,
};

export const fetchLocations = createAsyncThunk(
  "locations/fetchLocations",
  async (obj?: any) => {
    const queries = renderQueries(obj);
    const res = await request(`/locations${queries}`);
    return res.data;
  }
);

export const deleteLocations = createAsyncThunk(
  "locations/deleteLocations",
  async ({ data, parentId }: any) => {
    const res = await request(`/locations?parent=${parentId}`, {
      method: "delete",
      data,
    });
    return res.data;
  }
);

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    resetLocations() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLocations.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.data = {};
        state.status = "succeeded";
        state.data = action.payload.data;
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteLocations.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteLocations.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { data } = action.payload;
        const newData = state.data.filter((x: any) => !data.includes(x._id));
        state.data = newData;
      })
      .addCase(deleteLocations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetLocations } = locationsSlice.actions;

export default locationsSlice.reducer;

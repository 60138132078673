import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StateProps } from "redux/types";
import request, { renderQueries } from "utils/request";

const initialState: StateProps = {
  data: {},
  status: "idle",
  error: null,
};

export const fetchReview = createAsyncThunk(
  "review/fetchReview",
  async ({ id, ...obj }: any) => {
    const queries = renderQueries(obj);
    const res = await request(`/reviews/${id}${queries}`);
    return res.data;
  }
);

export const addNewReview = createAsyncThunk(
  "review/addNewReview",
  async ({ data, ...obj }: any, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/reviews${queries}`, {
      method: "post",
      data,
    });
    return res.data;
  }
);

export const updateReview = createAsyncThunk(
  "review/updateReview",
  async ({ data, id, ...obj }: any, { rejectWithValue }) => {
    const queries = renderQueries(obj);
    const res = await request(`/reviews/${id}${queries}`, {
      method: "put",
      data: data,
    });
    return res.data;
  }
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    resetReview() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReview.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchReview.fulfilled, (state, action) => {
        state.data = {};
        state.status = "succeeded";
        state.data = action.payload.data;
      })
      .addCase(fetchReview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addNewReview.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(updateReview.fulfilled, (state, action) => {
        state.data = action.payload.data;
      });
  },
});

export const { resetReview } = reviewSlice.actions;

export default reviewSlice.reducer;

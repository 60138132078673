import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useForm, { renderValidations } from "hooks/useForm";
import { AuthContextProps, useAuth } from "hooks/useAuth";
import useQuery from "hooks/useQuery";
import Banner from "shared/Banner/Banner";
import LoginLayout from "layouts/LoginLayout";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { login, loading } = useAuth();
  const queries = useQuery();
  const email = queries.get("email");
  const activated = queries.get("activated") === "true";
  const [showBanner, setShowBanner] = useState<boolean>(false);

  const { form, updateForm, isValid } = useForm({
    validations: renderValidations([
      {
        id: "email",
        validations: { required: true, email: true },
      },
      {
        id: "password",
        required: true,
      },
    ]),
  });

  useEffect(() => {
    if (email && !form.email) {
      handleChange({ target: { id: "email", value: email } });
      setShowBanner(true);
    }
  }, [form.email]);

  function handleChange(e: any) {
    const { id, value } = e.target;
    updateForm({ id, value });
  }

  function handleSubmit() {
    const { email, password } = form;
    login({ email, password });
  }

  return (
    <LoginLayout
      id="LoginPage"
      title="Login"
      footer={
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Already have an account? {` `}
          <Link className="text-primary-600" to="/login">
            Sign in
          </Link>
        </span>
      }
      loading={loading}
      buttonDisabled={loading || !isValid}
      buttonTitle="Login"
      onSubmit={handleSubmit}
    >
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Email address
        </span>
        <Input
          id="email"
          value={form.email}
          onChange={handleChange}
          type="email"
          placeholder="example@example.com"
          className="mt-1"
        />
      </label>
      <label className="block">
        <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
          Password
          <Link to="/forgot/password" className="text-sm">
            Forgot password?
          </Link>
        </span>
        <Input
          id="password"
          type="password"
          className="mt-1"
          value={form.password}
          onChange={handleChange}
        />
      </label>
    </LoginLayout>
  );
};

export default PageLogin;

import axios from "axios";
import { getToken } from "./authority";
import def from "config/defaultSettings";
import pack from "../../package.json";

axios.interceptors.response.use(function (response) {
  console.log("[ PACKAGE VERSION ]", pack.version);
  try {
    if (response.headers["x-version"] > pack.version) {
      window.localStorage.setItem("version-update-needed", "true");
    }
    window.localStorage.setItem("version", pack.version);
  } catch (err) {
    console.error("[ AXIOS Interceptor ]", err);
  }
  return response;
});

async function request(url, option) {
  const ROOT = (option && option.baseURL) || def.ROOT_URL;
  const options = {
    baseURL: ROOT,
    ...option,
  };

  const headers = {
    headers: {
      Authorization: `Token ${getToken()}`,
    },
  };

  const newOptions = {
    ...options,
    ...headers,
  };

  const res = await axios(url, newOptions);
  return res;
}

export default request;

export function renderQueries(obj) {
  if (!obj) return "";
  const queryKeys = Object.keys(obj);

  const res = queryKeys.map((key) => {
    return `${key}=${obj[key]}`;
  });

  return "?" + res.join("&");
}

import { experiences } from "constants/experiences";
import { features } from "process";
import React, { useState } from "react";
import { FC } from "react";
import {
  experienceOptions,
  featureOptions,
  seasonOptions,
  regionOptions,
} from "../Create/options";
import TagDropdown, { TagProps } from "./TagDropdown";

export interface SearchHeaderProps {
  onChange: (event: any) => void;
  experience?: string;
  season?: string;
  feature?: string;
  region?: string;
}

const Tags: FC<SearchHeaderProps> = ({
  experience,
  season,
  region,
  feature,
  onChange,
}) => {
  function handleExperienceChange(e: TagProps) {
    onChange({ id: "experience", value: e });
  }
  function handleSeasonChange(e: TagProps) {
    onChange({ id: "season", value: e });
  }
  function handleFeatureChange(e: TagProps) {
    onChange({ id: "feature", value: e });
  }
  function handleRegionChange(e: TagProps) {
    onChange({ id: "region", value: e });
  }

  return (
    <div className="pt-5 !m-0 flex flex-row flex-wrap">
      <TagDropdown
        id="experience"
        placeholder="Experience"
        dropdown={experienceOptions.map((i) => ({
          name: i.value,
        }))}
        onChange={handleExperienceChange}
        value={experience}
      />
      {/* <TagDropdown
        id="season"
        placeholder="Season"
        dropdown={seasonOptions.map((i) => ({
          name: i.value,
        }))}
        onChange={handleSeasonChange}
        value={season}
      />
      <TagDropdown
        id="region"
        placeholder="Region"
        dropdown={regionOptions.map((i) => ({
          name: i.value,
        }))}
        onChange={handleRegionChange}
        value={region}
      /> */}
      {/* <TagDropdown
        id="feature"
        placeholder="Feature"
        dropdown={featureOptions.map((i) => ({
          name: i.value,
        }))}
        onChange={handleFeatureChange}
        value={feature}
      /> */}
    </div>
  );
};

export default Tags;

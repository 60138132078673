import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import useForm from "hooks/useForm";
import { useAuth } from "hooks/useAuth";
import validator from "validator";
import Progress from "shared/Progress/Progress";
import { renderValidations } from "hooks/useForm";

export interface PasswordInputProps {
  className?: string;
  onChange?: (e: any) => void;
  value?: string;
}

const PasswordInput: FC<PasswordInputProps> = ({
  className = "",
  onChange,
  value,
}) => {
  const [error, setError] = useState<string>("");
  const [strength, setStrength] = useState<number>(0);
  const [color, setColor] = useState<string>("");

  function handleChange(e: any) {
    const { id, value } = e.target;
    onChange && onChange({ id, value });
  }

  function handlePasswordChange({ target: { id, value } }: any) {
    let newColor = "";
    const strength = validator.isStrongPassword(value, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      returnScore: true,
      pointsPerUnique: 5,
      pointsPerRepeat: 5,
      pointsForContainingLower: 15,
      pointsForContainingUpper: 15,
      pointsForContainingNumber: 15,
      pointsForContainingSymbol: 15,
    });

    let newError = "";

    if (validator.isAlpha(value)) {
      newError = "Password must contain at least 1 number";
    }
    if (!/[A-Z]/.test(value)) {
      newError = "Password must contain at least 1 uppercase";
    }
    if (value.length < 8) {
      newError = "Password must be longer than 8 characters";
    }
    if (!value) {
      newError = "Please provide a password";
    }
    if (newError && strength >= 100) {
      newColor = "red";
    }

    onChange && onChange({ id, value });
    setStrength(strength);
    setError(newError);
    setColor(newColor);

    // setFormInit({
    //   [id]: value,
    //   strength,
    //   error,
    //   color,
    //   passwordValid: !error && strength >= 100,
    // });
  }

  return (
    <div className="space-y-5">
      <Input
        id="password"
        type="password"
        className="mt-1"
        value={value}
        onChange={handlePasswordChange}
      />
      {value && (
        <>
          <Progress color={color} percent={strength} size="sm" />
          {error && <div className="text-gray-700">{error}</div>}
        </>
      )}
    </div>
  );
};

export default PasswordInput;

import { FC } from "react";
import { HousingType } from "types";

export interface HousingInfoProps {
  data: HousingType;
}

const HousingInfo: FC<HousingInfoProps> = ({ data }): any => {
  if (
    !data.housing ||
    !data.housingType ||
    !data.additionalRules ||
    !data.housingDescription
  ) {
    return;
  }
  return (
    data.housing &&
    data.housing !== "Not provided" && (
      // location.data.bio &&
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Housing</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex flex-col space-y-6 text-neutral-600 dark:text-neutral-300 whitespace-pre-wrap">
          {data.housing === "Provided at cost" && (
            <div className="flex flex-col space-y-1">
              <span className="text-lg font-semibold">Housing Pricing</span>
              <span className="">
                {data.housing +
                  ": " +
                  (data.housingCost ? data.housingCost : "")}
              </span>
            </div>
          )}
          {data.housingType && (
            <div className="flex flex-col space-y-1">
              <span className="text-lg font-semibold">Provided units</span>
              <span className="">{data.housingType}</span>
            </div>
          )}
          {data.additionalRules && (
            <div className="flex flex-col space-y-1">
              <span className="text-lg font-semibold">Additional Rules</span>
              <span className="">
                {data.additionalRules}
                {/* // .split(", ").map((i) => i)} */}
              </span>
            </div>
          )}
          {data.housingDescription && (
            <div className="flex flex-col space-y-1">
              <span className="text-lg font-semibold">Description</span>
              <span className="">{data.housingDescription}</span>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default HousingInfo;

import { FC } from "react";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import { ExperienceType } from "constants/experiences";
import CardExperience from "components/CardExperience/CardExperience";

export interface LocationsExperienceProps {
  data: ExperienceType[];
  onClick?: (item: ExperienceType) => void;
}

const LocationsExperience: FC<LocationsExperienceProps> = ({
  data,
  onClick,
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 grid-rows-2">
      <div className="col-span-1 lg:row-span-2 flex">
        <CardExperience onClick={onClick} taxonomy={data[0]} />
      </div>
      <div className="col-span-1 flex">
        <CardExperience onClick={onClick} taxonomy={data[1]} />
      </div>
      <div className="col-span-1 lg:row-span-2 flex">
        <CardExperience onClick={onClick} taxonomy={data[2]} />
      </div>
      <div className="col-span-1 flex">
        <CardExperience onClick={onClick} taxonomy={data[3]} />
      </div>
    </div>
  );
};

export default LocationsExperience;
